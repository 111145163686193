import Tag from 'components/Tag/Tag'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'components/index'
import { H2, H3, H6, P3 } from 'components/Typography'
import HorizontalProductsCarousel from 'components/Carousel/HorizontalProductsCarousel'
import CounterInput from 'components/CounterInput/CounterInput'
// import UserImage from 'components/User/UserImage'
import { useArticulos } from 'contexts/ArticulosContext'
import { useProveedor } from 'contexts/ProveedorContext'
import { useCarrito } from 'contexts/CarritoContext'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from 'components/Loading/Loading'
import CurrencyFormat from 'react-currency-format'
import { getDayAndMonthFromFullDate, replaceText } from 'utils/textProcess'
import { showToast } from 'components/Notification/ToastNotification'

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 40px;
  padding-bottom: 400px;
`

const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const ContainerProduct = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    flex-direction: column-reverse;
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 40px;

  @media (max-width: 440px) {
    padding: 20px;
  }
`

const InfoColumn = styled.div`
  width: 60%;

  @media (max-width: 440px) {
    width: 80%;
  }
`

const InfoRightColumn = styled.div`
  width: 80%;
  margin-top: 60px;

  @media (max-width: 440px) {
    margin-top: 20px;
    width: 100%;
  }
`

const RightSection = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 40px;
`

const StyledTag = styled(Tag)`
  margin-top: 6px;
`

const ProductImage = styled.img`
  width: 100%;
  max-height: 440px;
  object-fit: contain;
  border-radius: 20px;
`

const ImageContainer = styled.div`
  margin: 20px 40px 20px 0px;
`

const InfoProduct = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const TableDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Headers = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  font-weight: bold;

  div {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
`

const TableColumn = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-left: 4px solid ${({ theme }) => theme.colors.primary.darkBlue};
  padding: 8px;
  box-sizing: border-box;
  margin-top: 16px;
`

const CounterInputStyled = styled(CounterInput)`
  width: 80px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const AddButton = styled(Button)`
  margin-top: 32px;
`

const ProductTitle = styled(H2)`
  margin-top: 16px;
`

// const UserImageStyled = styled(UserImage)`
//   margin-right: 10px;
// `

const H6Styled = styled(H6)`
  margin-right: 4px;
`

const ProductoDetalle = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { articulo, getArticulo, articulosLoading, articulos, getArticulos } =
    useArticulos()
  const { currentProveedor, getProveedor } = useProveedor()
  const { agregarItem } = useCarrito()
  const [requerimientos, setRequerimientos] = useState()
  const [otrosProductos, setOtrosProductos] = useState([])

  const getArticuloId = () => {
    const routeParts = location.pathname.split('/')
    return routeParts[routeParts.length - 1]
  }

  const getArticulosVariados = () => {
    let articulosVariados = []
    if (articulo?.articulo && articulos?.articulos?.length > 0) {
      articulosVariados = articulos.articulos.map((a) => {
        const max = a.articulos.length - 1
        const i = Math.floor(Math.random() * (max + 1))

        return a.articulos[i]
      })
      if (articulosVariados && articulosVariados.length > 0) {
        return articulosVariados
      }
    }
    return []
  }

  const formatData = (requerimientos) => ({
    minimos: {
      bultos: requerimientos.minimo_log?.bultos_minimo
        ? requerimientos.minimo_log?.bultos_minimo
        : requerimientos.minimo_log[0]?.bultos_minimo,
      pallets: requerimientos.minimo_log?.pallet_minimo
        ? requerimientos.minimo_log?.pallet_minimo
        : requerimientos.minimo_log[0]?.pallet_minimo,
      valorizado: requerimientos.minimo_log?.valorizado_minimo
        ? requerimientos.minimo_log?.valorizado_minimo
        : requerimientos.minimo_log[0]?.valorizado_minimo,
      pallet_camion: requerimientos.minimo_log?.pallet_camion
        ? requerimientos.minimo_log?.pallet_camion
        : requerimientos.minimo_log[0]?.pallet_camion,
    },
    cond_pago: {
      texto_cond_pago: requerimientos.condiciones?.texto_cond_pago,
      desc_pronto_pago: requerimientos.condiciones?.desc_pronto_pago,
    },
    observacion:
      requerimientos.condiciones?.observacion === ''
        ? 'No hay observaciones'
        : requerimientos.condiciones?.observacion,
  })

  const onClickComprarProveedores = async (idProveedor) => {
    navigate(
      `/comprar/${replaceText(currentProveedor?.nombre_comercial, ' ', '-')}/id/${idProveedor}`,
    )
  }

  useEffect(() => {
    const idArticulo = getArticuloId()
    getArticulo(idArticulo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (articulo?.articulo?.id_proveedor) {
      getProveedor(articulo.articulo.id_proveedor)
      getArticulos(articulo.articulo.id_proveedor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulo])

  useEffect(() => {
    if (currentProveedor?.requerimientos) {
      setRequerimientos(formatData(currentProveedor?.requerimientos))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProveedor])

  useEffect(() => {
    if (articulos?.articulos?.length > 0) {
      setOtrosProductos(getArticulosVariados())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulos])

  const handleAgregarItem = async (id_articulo, cant, idProveedor) => {
    try {
      await agregarItem(id_articulo, cant, idProveedor)
    } catch (error) {
      console.error('Error al actualizar el carrito:', error)
      await getArticulos(idProveedor)
    }
  }

  return (
    <Container>
      <ContainerProduct>
        {articulosLoading ? (
          <>
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          </>
        ) : (
          <>
            <LeftSection>
              <InfoColumn>
                <ImageContainer>
                  <ProductImage
                    src={articulo?.articulo?.path}
                    alt={articulo?.articulo?.descripcion}
                  />
                </ImageContainer>
                <InfoProduct>
                  <H3> Detalles de Producto </H3>
                  <TableDetails>
                    <Row>
                      <H6Styled> Código CCFACC:</H6Styled>
                      <P3> {articulo?.articulo?.id_pp || 'No disponible'} </P3>
                    </Row>
                    <Row>
                      <H6Styled> Código Ean13: </H6Styled>
                      <P3> {articulo?.articulo?.ean13 || 'No disponible'} </P3>
                    </Row>
                    <Row>
                      <H6Styled> Código Dun14: </H6Styled>
                      <P3> {articulo?.articulo?.dun14 || 'No disponible'} </P3>
                    </Row>
                    <Row>
                      <H6Styled> Unidad de Medida: </H6Styled>
                      <P3>
                        {' '}
                        {articulo?.articulo?.unidad_medida ||
                          'No disponible'}{' '}
                      </P3>
                    </Row>
                    <Row>
                      <H6Styled> Múltiplo: </H6Styled>
                      <P3>
                        {' '}
                        {articulo?.articulo?.multiplo || 'No disponible'}{' '}
                      </P3>
                    </Row>
                    <Row>
                      <H6Styled> Unidades x Bulto: </H6Styled>
                      <P3>
                        {' '}
                        {articulo?.articulo?.bultos_camada ||
                          'No disponible'}{' '}
                      </P3>
                    </Row>
                    <Row>
                      <H6Styled> Observaciones: </H6Styled>
                      <P3>
                        {' '}
                        {articulo?.articulo?.observacion ||
                          'No disponible'}{' '}
                      </P3>
                    </Row>
                    <Row>
                      <H6Styled> Última actualización de precio:</H6Styled>
                      <P3>
                        {getDayAndMonthFromFullDate(
                          articulo?.articulo?.fecha_precio ||
                            new Date().toDateString(),
                        )}
                      </P3>
                    </Row>
                  </TableDetails>
                  <H3> Detalles de Proveedor </H3>
                  <TableDetails>
                    <Row>
                      {/* <UserImageStyled
                        src={currentProveedor.path}
                        alt="imagen de perfil"
                      /> */}
                      <H6Styled>{currentProveedor.nombre_comercial}</H6Styled>
                    </Row>
                    <Row>
                      {/* prettier-ignore */}
                      <P3>
                      Condición de pago: 
                      {requerimientos?.cond_pago?.texto_cond_pago || 'No disponible'} - Dto Pronto 
                      Pago: {requerimientos?.cond_pago?.desc_pronto_pago || '0'} %
                      Minimos: Bultos {requerimientos?.minimos?.bultos || '0'},
                      pallets {requerimientos?.minimos?.pallets || '0'},
                      valorizado 
                        <CurrencyFormat
                          value={parseFloat(requerimientos?.minimos?.valorizado) || 0}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={' $'}
                          decimalScale={3}
                          fixedDecimalScale={true}
                        /> 
                        pallets por camion {requerimientos?.minimos?.pallet_camion || '0'}. 
                        Precios unitarios sin impuestos.
                      </P3>
                    </Row>
                  </TableDetails>
                </InfoProduct>
              </InfoColumn>
            </LeftSection>
            <RightSection>
              <InfoRightColumn>
                <Tag> {currentProveedor?.nombre_comercial} </Tag>
                {/* prettier-ignore */}
                <ProductTitle>
                  {articulo?.articulo?.descripcion} {articulo?.articulo?.gramaje} {articulo?.articulo?.unidad_medida} (x {articulo?.articulo?.bultos_camada} Uni.)
                </ProductTitle>
                {articulo?.articulo?.id_promocion !== '0' && (
                  <StyledTag>PROMO</StyledTag>
                )}
                <Headers>
                  <H6Styled>PRECIO</H6Styled>
                  <H6Styled>Unit</H6Styled>
                  <H6Styled>Bulto</H6Styled>
                </Headers>
                {articulo?.articulo?.precios?.map(
                  (precio) =>
                    precio && (
                      <TableColumn key={precio?.zona || 'desconocido'}>
                        <P3>Precio {precio?.zona || 'No disponible'}</P3>
                        <P3>
                          <CurrencyFormat
                            value={parseFloat(precio?.precio) || 0}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'$'}
                            decimalScale={3}
                            fixedDecimalScale={true}
                          />
                        </P3>
                        <P3>
                          <CurrencyFormat
                            value={
                              (parseFloat(precio?.precio) || 0) *
                              (articulo?.articulo?.bultos_camada || 1)
                            }
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'$'}
                            decimalScale={3}
                            fixedDecimalScale={true}
                          />
                        </P3>
                      </TableColumn>
                    ),
                )}
                <TableColumn>
                  <P3>IVA</P3>
                  <P3>{articulo?.articulo?.iva}%</P3>
                </TableColumn>
                <TableColumn>
                  <P3>Impuestos internos</P3>
                  <P3>{articulo?.articulo?.impuestos_internos}%</P3>
                </TableColumn>
                <TableColumn>
                  <P3>Cantidad</P3>
                  <CounterInputStyled
                    amount={articulo?.articulo?.cantidad_carrito}
                    onClick={async (cant) => {
                      await handleAgregarItem(
                        articulo?.articulo?.id_articulo,
                        cant,
                        currentProveedor.id,
                      )
                      showToast(
                        `Artículo modificado en el carrito!`,
                        'validacion',
                      )
                      getArticulos(currentProveedor.id)
                      const idArticulo = getArticuloId()
                      getArticulo(idArticulo)
                    }}
                    disabled={
                      !(
                        currentProveedor.habilitado &&
                        currentProveedor.puedePedir.puedePedir
                      )
                    }
                    // multiplo={parseInt(articulo?.articulo?.multiplo)}
                  />
                </TableColumn>
                <AddButton
                  text="Volver al surtido"
                  onClick={() =>
                    onClickComprarProveedores(currentProveedor?.id)
                  }
                />
              </InfoRightColumn>
            </RightSection>
          </>
        )}
      </ContainerProduct>
      <HorizontalProductsCarousel
        title="Otros productos del proveedor"
        type="primary"
        cards={otrosProductos}
        proveedor={currentProveedor}
        puedePedir={
          currentProveedor.habilitado && currentProveedor.puedePedir.puedePedir
        }
      />
    </Container>
  )
}

export default ProductoDetalle
