import React, { useState } from 'react'
import styled from 'styled-components'
import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import { H4 } from 'components/Typography'

const Wrapper = styled.div`
  flex-direction: column;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  &:last-child {
    border-radius: 0 0 15px 15px;
  }
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const CardHeader = styled.header`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
  border-left: ${({ removeBlueBorder, theme }) =>
    removeBlueBorder ? '' : `3px solid ${theme.colors.primary.darkBlue}`};
  &.isFirst {
    border-radius: 10px 10px 0 0;
  }
  &.isLast {
    border-radius: 0 0 10px 10px;
    &.isExpanded {
      border-radius: 0;
    }
  }
  &.isOnly {
    border-radius: 10px;
    &.isExpanded {
      border-radius: 10px 10px 0 0;
    }
  }
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  display: flex;
  justify-content: space-between;
  border: 1px solid #c8c8c8;
  flex-direction: row;
  align-items: center;
  padding: 14px 44px;
`

const CardActions = styled.div`
  display: flex;
  gap: 12px;
`

const CardContent = styled.div`
  display: ${({ $isExpanded }) => ($isExpanded ? 'flex' : 'none')};
  transition: all 0.5s ease-in-out;
  &.isLast {
    border-radius: 0 0 10px 10px;
  }
`

const CardTitleComponent = styled.div`
  flex-grow: 1;
`

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-weight: 600;
  font-size: 14px;
`

const TitleTab = styled(H4)`
  width: auto;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const Spacer = styled.div`
  width: 17px;
`

const ExpandableTables = ({ items, openTables = [], className, style }) => {
  const [expandedItems, setExpandedItems] = useState(
    openTables.length !== 0
      ? items
          .filter((item) => openTables.includes(item.title))
          .map((item) => item.title)
      : [],
  )
  const handleClick = (title) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(title)
        ? prevExpandedItems.filter((item) => item !== title)
        : [...prevExpandedItems, title],
    )
  }

  return (
    <>
      {items.map((item, index) => {
        const isExpanded = expandedItems.includes(item.title)
        const isFirst = index === 0
        const isLast = index === items.length - 1
        const isOnly = items.length === 1
        return (
          <Wrapper key={index} $isExpanded={isExpanded} style={style}>
            <CardHeader
              className={
                className +
                `${isExpanded ? ' isExpanded ' : ''} + ${isOnly ? 'isOnly' : isFirst ? ' isFirst' : isLast ? ' isLast' : ''}`
              }
              removeBlueBorder={className?.removeBlueBorder}
            >
              <TitleTab>{item.title}</TitleTab>
              <CardTitleComponent>
                {item.componentTitle && item.componentTitle}
              </CardTitleComponent>
              <CardActions>
                <StyledButton onClick={() => handleClick(item.title)}>
                  {isExpanded ? (
                    <>
                      <span>Ver menos</span>
                      <ChevronUp />
                    </>
                  ) : (
                    <>
                      <Spacer />
                      <span>Ver mas</span>
                      <ChevronDown />
                    </>
                  )}
                </StyledButton>
              </CardActions>
            </CardHeader>
            <CardContent
              $isExpanded={isExpanded}
              className={className + `${isLast ? ' isLast' : ''}`}
            >
              {item.component}
            </CardContent>
          </Wrapper>
        )
      })}
    </>
  )
}

export default ExpandableTables
