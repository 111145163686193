import React from 'react'
import styled from 'styled-components'

import { BtnS } from 'components/Typography'

const CardContent = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-top: 25px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 32px;
`

const RequerimientosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`

const DataBox = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.darkSkyBlue};
  border-radius: 5px;
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`

const Minimos = styled.div`
  display: flex;
  flex-direction: column;
  border-left: ${({ theme, $hasborder }) =>
    $hasborder ? 'solid 1px ' + theme.colors.primary.darkBlue : 'none'};
  padding-left: 6px;
`

const DescriptionRequerimientosProveedor = ({ requerimientos }) => {
  const formatData = (requerimientos) => {
    const data = []

    if (requerimientos.minimo_log) {
      const minimoLogKeys = [
        { nombre: 'Bultos', key: 'bultos_minimo' },
        { nombre: 'Pallets', key: 'pallet_minimo' },
        { nombre: 'Valorizado', key: 'valorizado_minimo' },
        { nombre: 'Pallet camión', key: 'pallet_camion' },
      ]

      const minimos = minimoLogKeys.map(({ nombre, key }) => {
        let valor =
          requerimientos.minimo_log[key] ||
          requerimientos.minimo_log[0]?.[key] ||
          null

        if (nombre == 'Valorizado')
          valor = parseFloat(valor).toLocaleString('es-ar', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0,
          })

        return { nombre, valor }
      })

      data.push({
        titulo: 'Mínimos',
        valor: minimos,
      })
    }

    data.push(
      {
        titulo: 'Condición de pago',
        valor: requerimientos.condiciones?.texto_cond_pago,
      },
      {
        titulo: 'Observación',
        valor:
          requerimientos.condiciones?.observacion === ''
            ? 'No hay observaciones'
            : requerimientos.condiciones?.observacion,
      },
    )

    return data
  }

  return (
    <CardContent>
      {formatData(requerimientos).map((item, index) => {
        return (
          <RequerimientosWrapper key={index}>
            <div>{item.titulo}</div>
            {item.titulo !== 'Mínimos' ? (
              <DataBox key={index}>
                <BtnS>{item.valor}</BtnS>
              </DataBox>
            ) : (
              <DataBox>
                {item.valor && (
                  <>
                    {item.valor.map((j, index) => (
                      <Minimos key={j.nombre} $hasborder={index !== 0}>
                        <BtnS>{j.valor}</BtnS>
                        <BtnS>{j.nombre}</BtnS>
                      </Minimos>
                    ))}
                  </>
                )}
              </DataBox>
            )}
          </RequerimientosWrapper>
        )
      })}
    </CardContent>
  )
}

export default DescriptionRequerimientosProveedor
