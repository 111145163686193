import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { H2, P2 } from 'components/Typography'
import TabsComponentPedidos from './components/TabsComponentPedidos'
import PedidosAgrupacionTable from './components/PedidosAgrupacion/PedidoAgrupacionTable'
import MisPedidosTable from './components/MisPedidos/MisPedidosTable'
import { useAuth } from 'contexts/AuthContext'
import { usePedidos } from 'contexts/PedidosContext'
import FilterSection from './components/FilterSection'
import Pagination from 'components/Table/Pagination'
import NumberElement from 'components/Table/NumberElement'
import { useFilters } from 'contexts/FiltersContext'

const WrapperPedidosPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;

  @media (max-width: 440px) {
    padding: 10px 20px;
  }
`

const FilterSectionWrapper = styled.div`
  width: 20%;

  @media (max-width: 440px) {
    display: none;
  }
`

const TableSection = styled.div`
  width: 75%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled.div`
  padding: 10px 16px 60px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    padding: 8px 8px 40px;
  }
`

const CustomPagination = styled(Pagination)`
  justify-content: end;
  margin: 20px 0;
  margin-left: auto;
`

const CustomNumberElement = styled(NumberElement)`
  text-align: end;
`

const PedidosPage = (asociada) => {
  const { user } = useAuth()
  const {
    pedidosAgrupacion,
    misPedidos,
    obtenerPdfPedidoSubasociada,
    obtenerPdfPedidoAgrupacion,
  } = usePedidos()
  const [searchValue, setSearchValue] = useState('')
  const { pedidosFilters, onPedidosFiltersChange } = useFilters()
  const [currentPage, setCurrentPage] = useState(1)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    setCurrentPage(1)
  }, [pedidosFilters])

  const pedidos =
    user.rol.id_rol !== '1'
      ? misPedidos
      : activeTab === 0
        ? pedidosAgrupacion
        : misPedidos

  const tabs =
    user.rol.id_rol === '1'
      ? ['Pedidos Agrupación', 'Mis pedidos']
      : ['Mis pedidos']

  const formatAsociadasDataToDisplay = (obj) =>
    obj
      ? {
          id: obj.id,
        }
      : {}

  const dataToDisplay = formatAsociadasDataToDisplay(asociada)

  const onClickPedidoDescarga = async (type, pedidoId, documentName) => {
    const pdfData =
      type === 'subasociada'
        ? await obtenerPdfPedidoSubasociada(pedidoId)
        : await obtenerPdfPedidoAgrupacion(pedidoId)
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `OC-${documentName}-ID${pedidoId}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <WrapperPedidosPage>
      <FilterSectionWrapper>
        <FilterSection
          filters={pedidosFilters}
          setFilters={onPedidosFiltersChange}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          activeTab={activeTab}
        />
      </FilterSectionWrapper>
      <TableSection>
        <Header>
          <Title>
            <H2>{user.rol.id_rol === '3' ? 'Mis pedidos' : 'Pedidos'}</H2>
            <P2>
              {pedidos?.pager?.total !== 0 &&
              pedidos?.pager?.total !== undefined
                ? pedidos?.pager?.total + ' resultados'
                : 'sin resultados'}
            </P2>
          </Title>
        </Header>
        {user.rol.id_rol == 1 ? (
          <TabsComponentPedidos
            $tabs={tabs}
            asociada={dataToDisplay}
            activeTab={activeTab}
            setActiveTab={(index) => {
              setActiveTab(index)
              onPedidosFiltersChange(pedidosFilters)
              setCurrentPage(1)
            }}
          >
            <PedidosAgrupacionTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              isActive={activeTab === 0}
              searchValue={searchValue}
            />
            <MisPedidosTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              isActive={activeTab === 1}
              searchValue={searchValue}
            />
          </TabsComponentPedidos>
        ) : (
          <Wrapper>
            <MisPedidosTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              isActive={true}
              searchValue={searchValue}
            />
          </Wrapper>
        )}
        <CustomPagination
          totalPages={pedidos?.pager?.ultima_pagina}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
        <CustomNumberElement
          currentElement={
            pedidos?.pager
              ? pedidos?.pager.por_pagina * pedidos?.pager?.pagina_actual -
                pedidos?.pager?.por_pagina +
                pedidos.pedidos?.length
              : 0
          }
          totalElements={pedidos?.pager?.total}
        />
      </TableSection>
    </WrapperPedidosPage>
  )
}

export default PedidosPage
