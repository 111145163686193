import React, { useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  AlertTriangle,
  Bell,
  CheckCircle,
  Slash,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents'
import IconButton from 'components/Button/IconButton'
import { H2, P1 } from 'components/Typography'
import { useModal } from 'contexts/ModalContext'
import Button from 'components/Button/Button'
import RotatingLoader from 'components/Loading/RotatingLoader'

const BackgroundOverlay = styled.div`
  background-color: #00000096;
  width: 100%;
  height: 100%;
  z-index: 1198;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
`

const StyledModal = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  position: absolute;
  max-width: 80%;
  width: 600px;
  min-height: max-content;
  max-height: 80%;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1199;
  box-sizing: content-box;
`

const XIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const PrimaryText = styled(H2)`
  color: ${({ theme, type }) => {
    if (type === 'info') {
      return theme.colors.primary.blue
    } else if (type === 'error') {
      return theme.colors.alarmsStatuses.red
    } else if (type === 'success') {
      return theme.colors.alarmsStatuses.green
    } else if (type === 'alert') {
      return theme.colors.alarmsStatuses.yellow
    }
  }};
  max-width: 350px;
  margin: 20px;
`

const GrayBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 50px;
`

const StyledP = styled.p`
  text-wrap: balance;
  line-break: loose;
`

const ConfirmText = styled(P1)`
  color: ${({ theme }) => theme.colors.neutral.gray};
  font-family: 'Roboto-bold';
  font-weight: 700;
`

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 8px 0;
`

const StyledButton = styled(Button)`
  text-decoration: none;
`

const Modal = ({ content, cronogramaNumber, children, className }) => {
  const { closeModal } = useModal()
  const InputRef = useRef()
  const theme = useTheme()

  return (
    <BackgroundOverlay>
      <StyledModal ref={InputRef} className={className}>
        {
          <XIconButton
            icon={<X />}
            onClick={() => {
              content.customCloseModal
                ? content.customCloseModal()
                : closeModal()
              if (content.afterClose) {
                content.afterClose()
              }
            }}
          />
        }
        {content.loading ? (
          <RotatingLoader />
        ) : content.icon === 'check' ? (
          <CheckCircle color={theme.colors.alarmsStatuses.green} size={40} />
        ) : content.icon === 'trash' ? (
          <Trash2 color={theme.colors.alarmsStatuses.red} size={40} />
        ) : content.icon === 'alert' ? (
          <AlertTriangle color={theme.colors.alarmsStatuses.yellow} size={40} />
        ) : content.icon === 'slash' ? (
          <Slash color={theme.colors.alarmsStatuses.red} size={40} />
        ) : content.icon === 'bell' ? (
          <Bell color={theme.colors.primary.blue} size={40} />
        ) : null}
        <PrimaryText type={content.type}>
          {content.title}
          {cronogramaNumber}
        </PrimaryText>
        {children}
        {content.text && content.text.length > 0 && (
          <GrayBackground>
            <StyledP>
              {content.text.map((item, index) => {
                if (index % 2 === 1) {
                  return <strong key={index}>{item}</strong>
                } else {
                  return item
                }
              })}
            </StyledP>
          </GrayBackground>
        )}
        {content.hasConfirmText === true && (
          <ConfirmText>¿deseas avanzar?</ConfirmText>
        )}
        {(content.primaryButton || content.secondaryButton) && (
          <ButtonContainer>
            {content.secondaryButton && content.secondaryButton.function && (
              <StyledButton
                type="tertiary"
                text={content.secondaryButton.text}
                onClick={content.secondaryButton.function}
                disabled={content.secondaryButton.disabled}
              />
            )}
            {content.primaryButton && content.primaryButton.function && (
              <Button
                type="primary"
                text={content.primaryButton.text}
                onClick={content.primaryButton.function}
                disabled={content.primaryButton.disabled}
              />
            )}
          </ButtonContainer>
        )}
      </StyledModal>
    </BackgroundOverlay>
  )
}

export default Modal
