import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import checkImg from 'assets/Check.svg'
import closeImg from 'assets/Close.svg'
import RotatingLoader from 'components/Loading/RotatingLoader'

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${({ $disable }) => ($disable ? 'auto' : 'pointer')};
`

const Switch = styled.div`
  position: relative;
  width: 48px;
  height: 30px;
  background-color: ${({ theme, $disable }) =>
    $disable ? theme.colors.neutral.gray : theme.colors.neutral.white};
  border-radius: 32px;
  padding: 2px 4px;
  transition: 300ms all;
  border: 2px solid
    ${({ theme, $disable }) =>
      $disable ? theme.colors.neutral.gray : theme.colors.primary.lightBlue};
       &:before {
          transition: 300ms all;
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 35px;
            top: 50%;
            left: 4px;
  ${({ $disable }) =>
    $disable
      ? css`
            background-color: ${({ theme }) => theme.colors.neutral.lighterGray};
            transform: translate(0, -50%);
            background-repeat: no-repeat;
            background-position: center;
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.primary.lightBlue};
          transform: translate(0, -50%);
          background-image: url('${closeImg}');
          background-repeat: no-repeat;
          background-position: center;
        `}
`

const ContentRotatingLoader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 30px;
  border-radius: 32px;
  padding: 2px 4px;
  transition: 300ms all;
  border: 2px solid ${({ theme }) => theme.colors.primary.lighterBlue};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const Input = styled.input`
  opacity: 0;
  position: absolute;

  ${({ $disable }) =>
    !$disable &&
    css`
      &:checked + ${Switch} {
        background-color: ${({ theme, $disable }) =>
          $disable
            ? theme.colors.neutral.disableGrey
            : theme.colors.primary.blue};
        border-color: ${({ theme, $disable }) =>
          $disable
            ? theme.colors.neutral.disableGrey
            : theme.colors.primary.blue};
        &:before {
          transform: translate(24px, -50%);
          background-color: ${({ theme }) => theme.colors.neutral.white};
          background-image: url('${checkImg}');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    `}
`

const ToggleSwitch = ({ label, onClick, disable, isChecked }) => {
  const [isLoading, setLoading] = useState(false)

  const handleChange = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      await onClick()
    } catch (error) {
      console.error('Error al cambiar:', error)
    }
    setLoading(false)
  }

  return (
    <Label $disable={disable} onClick={disable ? () => {} : handleChange}>
      {label && <span>{label}</span>}
      <Input
        checked={isChecked}
        $isLoading={isLoading}
        $disable={disable}
        type="checkbox"
      />
      {isLoading ? (
        <ContentRotatingLoader>
          <RotatingLoader />
        </ContentRotatingLoader>
      ) : (
        <Switch $disable={disable} />
      )}
    </Label>
  )
}

export default ToggleSwitch
