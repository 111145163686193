import React from 'react'
import styled from 'styled-components'

import { H2, P2 } from 'components/Typography'
import CarritoTable from './components/CarritoTable'
import { useCarrito } from 'contexts/CarritoContext'

import { totalArticulos } from './utils'
import RotatingLoading from 'components/Loading/Loading'
import { showToast } from 'components/Notification/ToastNotification'

const WrapperComprarPage = styled.div`
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;

  @media (max-width: 440px) {
    padding: 20px;
    width: 90%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const TextProduct = styled.span`
  font-weight: 400;
`

const ProductCount = ({ providers, products }) => (
  <P2>
    {providers} proveedores <TextProduct>{products} bultos</TextProduct>
  </P2>
)

const CarritoPage = () => {
  const { carrito, loadingCarrito, loadingCarritoFinalizar } = useCarrito()

  async function confirmFeedback() {
    showToast('Tu pedido ha sido confirmado', 'validacion')
  }

  return (
    <WrapperComprarPage>
      <Header>
        <H2>Mi carrito</H2>
        <ProductCount
          providers={carrito?.carritos?.length}
          products={totalArticulos(carrito?.carritos)}
        />
      </Header>
      {loadingCarrito || loadingCarritoFinalizar ? (
        <RotatingLoading size="large" />
      ) : (
        <CarritoTable confirmFeedback={confirmFeedback} />
      )}
    </WrapperComprarPage>
  )
}

export default CarritoPage
