import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`

const BtnM = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default BtnM
