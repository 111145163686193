import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { useCronograma } from './CronogramaContext'
import { useCarrito } from './CarritoContext'
import AuthService from 'services/auth'
import AuthCronograma from 'services/cronograma'
import CarritoService from 'services/carrito'
import ProveedoresService from 'services/proveedores'
import { useProveedores } from './ProveedoresContext'
import { useArticulos } from './ArticulosContext'
import ArticulosService from 'services/articulos'

export const InitialDataContext = createContext()
export const useInitialData = () => useContext(InitialDataContext)

export const InitialDataProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [errorLoadingCronograma, setErrorLoadingCronograma] = useState(false)
  const [errorLoadingData, setErrorLoadingData] = useState(false)
  const { setUser, token } = useAuth()
  const { setCronograma, cronograma } = useCronograma()
  const { setCarritoAgrupado } = useCarrito()
  const { setProveedoresActivos, setProveedoresDehabilitados } =
    useProveedores()
  const { setPromociones } = useArticulos()

  const saveToLocalStorage = (key, data) => {
    if (data) {
      localStorage.setItem(key, JSON.stringify(data))
    }
  }

  const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key)
    try {
      return data ? JSON.parse(data) : null
    } catch (error) {
      console.error(`Error parsing localStorage data for key "${key}":`, error)
      return null
    }
  }

  const fetchData = async (
    serviceFunction,
    args,
    localStorageKey,
    setStateFunction,
    idKey,
  ) => {
    try {
      let data = loadFromLocalStorage(localStorageKey)

      if (!data) {
        data = await serviceFunction(...args)
      }

      // Handle special cases
      if (localStorageKey === 'promociones') {
        const promocionesData = data?.promociones ? data.promociones : []
        setStateFunction(promocionesData)
        saveToLocalStorage(localStorageKey, promocionesData)
        return promocionesData
      }

      // Validate and set state
      if (!data?.[idKey]) {
        throw new Error(`${idKey.replace('_', ' ')} not found or is invalid`)
      }

      setStateFunction(data)
      saveToLocalStorage(localStorageKey, data)

      return data
    } catch (error) {
      console.error('Error fetching data:', error)
      throw error
    }
  }

  const loadInitialData = async () => {
    if (token && !errorLoadingData && !loading) {
      try {
        setLoading(true)

        const user = await fetchData(
          AuthService.getUserData,
          [token],
          'user',
          setUser,
          'id',
        )

        if (!user) {
          throw new Error('User data is undefined or invalid')
        }

        const cronogramaData = await fetchData(
          AuthCronograma.getCronogramaData,
          [token],
          'cronograma',
          setCronograma,
          'id_cronograma',
        )

        if (!cronogramaData || !cronogramaData.id_cronograma) {
          throw new Error('Cronograma data is undefined or invalid')
        }

        await fetchData(
          ProveedoresService.getProveedores,
          [
            {
              token,
              idCronograma: cronogramaData.id_cronograma,
              porPagina: 30,
              paginaActual: 1,
              habilitados: 1,
            },
          ],
          'proveedoresActivos',
          setProveedoresActivos,
          'proveedores',
        )

        await fetchData(
          ProveedoresService.getProveedores,
          [
            {
              token,
              idCronograma: cronogramaData.id_cronograma,
              porPagina: 30,
              paginaActual: 1,
              habilitados: 0,
            },
          ],
          'proveedoresDehabilitados',
          setProveedoresDehabilitados,
          'proveedores',
        )

        const carrito = await fetchData(
          CarritoService.getCarritoAgrupado,
          [{ token, idCronograma: cronogramaData.id_cronograma }],
          'carritoAgrupadoData',
          setCarritoAgrupado,
          'cabecera',
        )

        if (!carrito || !carrito.cabecera?.id) {
          throw new Error('Carrito data is undefined or invalid')
        }

        await fetchData(
          ArticulosService.getPromociones,
          [
            {
              token,
              idCronograma: cronogramaData.id_cronograma,
              idCarrito: carrito.cabecera.id,
            },
          ],
          'promociones',
          setPromociones,
          'promociones',
        )
      } catch (error) {
        if (!cronograma || Object.keys(cronograma).length === 0) {
          setErrorLoadingCronograma(true)
        }
        console.error('Error during initial data load:', error)
        setErrorLoadingData(true)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (token) {
      loadInitialData()
    }
  }, [token])

  const contextValues = {
    loadInitialData,
    errorLoadingCronograma,
    setErrorLoadingData,
    loading,
    errorLoadingData,
  }

  return (
    <InitialDataContext.Provider value={contextValues}>
      {children}
    </InitialDataContext.Provider>
  )
}
