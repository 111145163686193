import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BtnS, H2, P2 } from 'components/Typography'
import { Button } from 'components/index'
import StepsComponent from './StepsComponent'
import { Download } from 'feather-icons-react/build/IconComponents'
import TotalesSection from './TotalesSection'
import { useNavigate } from 'react-router-dom'
import { usePedidos } from 'contexts/PedidosContext'
import {
  ESTADOS_HABILITADOS,
  ESTADOS_OCULTAR_COMPROBANTES_STR,
  obtenerEstadoPedido,
} from 'utils/EstadosPedidos'
import StatusTag from 'components/Tag/StatusTag'
import { replaceText } from 'utils/textProcess'

const Header = styled.div`
  display: flex;
  align-items: start;
  gap: 18px;
  margin-bottom: 20px;
  justify-content: space-between;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  justify-content: space-between;
  max-width: 40%;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 25px;
  width: 60%;
  margin-left: auto;
  flex-grow: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  row-gap: 26px;
  width: 100%;
`

const ButtonsRow = styled(Row)`
  margin-left: auto;
  gap: 12px;
  max-width: min-content;
`

const StateContainer = styled(BtnS)`
  background: ${({ theme, $disabled }) =>
    !$disabled ? theme.colors.neutral.white : theme.colors.neutral.disableGrey};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  border-radius: 8px;
  padding: 20px;
  width: 85%;
`

const TopSection = styled(Row)`
  justify-content: space-around;
  width: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 24px;
  }
`

const BottomSection = styled(Row)`
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 48px;
    align-items: end;
  }
`

const HeaderPedidoAgrupacion = ({ oc }) => {
  const navigate = useNavigate()
  const { obtenerPdfPedidoAgrupacion } = usePedidos()
  const steps = ['Aprobación', 'Preparación', 'Retiro', 'En destino']
  const [isStepsComponentEnabled, setIsStepsComponentEnabled] = useState(false)
  useEffect(() => {
    const checkIfStepsComponentIsEnabled = () => {
      return ESTADOS_HABILITADOS.includes(parseInt(oc.estado.id))
    }
    setIsStepsComponentEnabled(checkIfStepsComponentIsEnabled())
  }, [oc.estado.id])
  const EstadoPedido = obtenerEstadoPedido(oc.estado.id, oc.porcentajeRecepcion)
  const onClickPedidoDescarga = async (pedidoId, documentName) => {
    const pdfData = await obtenerPdfPedidoAgrupacion(pedidoId)
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${documentName}-ID${pedidoId}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <Header>
      <Title>
        <H2>{oc?.proveedor}</H2>
        <P2>{replaceText(oc?.ordenDeCompra, '-', '# ')}</P2>
        <StatusTag estado={oc?.estado.descripcion} />
      </Title>
      <InfoSection>
        <TopSection>
          <TotalesSection headerData={{ ...oc?.totales, fecha: oc.fecha }} />
          <ButtonsRow>
            <Button
              text="Ver comprobantes"
              size="small"
              type="secondary"
              onClick={() => {
                navigate(`/pedidos-agrupacion/comprobantes/${oc?.idOcCompra}`, {
                  state: {
                    idOcCompraCoordinadora: oc?.idOcCompra,
                    tipoProveedor: oc?.tipoProveedor,
                  },
                })
              }}
              disabled={ESTADOS_OCULTAR_COMPROBANTES_STR.includes(
                oc.estado.descripcion.toLowerCase(),
              )}
            />
            <Button
              text="Descargar"
              icon={<Download />}
              size="small"
              onClick={() => {
                onClickPedidoDescarga(oc?.idOcCompra, oc?.ordenDeCompra)
              }}
            />
          </ButtonsRow>
        </TopSection>
        <BottomSection>
          <StepsComponent
            steps={steps}
            currentStep={EstadoPedido.step}
            disabled={!isStepsComponentEnabled}
          />
          <StateContainer $disabled={!isStepsComponentEnabled}>
            {!isStepsComponentEnabled ? '' : EstadoPedido?.descripcion}
          </StateContainer>
        </BottomSection>
      </InfoSection>
    </Header>
  )
}

export default HeaderPedidoAgrupacion
