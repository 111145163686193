import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
`

const P4 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default P4
