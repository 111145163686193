import React from 'react'
import styled from 'styled-components'

const ProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 50%;
  margin-right: 4px;
  width: auto;
  height: 35px;
  aspect-ratio: 1/1;
  border: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    display: none;
  }
`

const ProfilePicture = styled.img`
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
`

const UserIcon = ({ src, alt, className }) => {
  return (
    <ProfilePictureContainer className={className}>
      <ProfilePicture src={src} alt={alt} />
    </ProfilePictureContainer>
  )
}

export default UserIcon
