import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString } from 'utils/textProcess'

const ProveedoresService = {
  getProveedores: async ({
    idCronograma,
    porPagina,
    paginaActual,
    familias,
    habilitados,
    tipo_proveedor,
    buscar,
    token,
    cancelToken,
  }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      pagina_actual: parseInt(paginaActual),
      por_pagina: parseInt(porPagina),
    }
    if (familias) data.familias = familias
    if (!isNaN(tipo_proveedor)) data.tipo_proveedor = parseInt(tipo_proveedor)
    if (buscar) data.buscar = buscar
    if (habilitados !== undefined) data.habilitados = parseInt(habilitados)
    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedores?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
      cancelToken,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        data.data.proveedores.map((p) =>
          p.nombre_comercial === 'AGROINDUSTRIAS LA ESPA?OLA S A'
            ? (p.nombre_comercial = 'AGROINDUSTRIAS LA ESPAÑOLA S A')
            : p,
        )
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
}

export default ProveedoresService
