import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'
import HeaderPedidoAgrupacion from '../HeaderComprobantes/HeaderPedidoAgrupacion'
import DetallesSubasociadasTable from './DetallesSubasociadasTable'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import PedidosAgrupadosTable from '../PedidosAgrupadosTable'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { usePedidos } from 'contexts/PedidosContext'
import dayjs from 'dayjs'
import Loading from 'components/Loading/Loading'
import { useLocation, useParams } from 'react-router-dom'

const WrapperAgrupacionPage = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  margin: 50px auto;
`

const TableSection = styled.div`
  width: 100%;
`

const WrapperTables = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const PedidosAgrupacionOrdenDeCompra = () => {
  const { user, token } = useAuth()
  const { cronograma } = useCronograma()
  const { getOcAgrupacion, setOcAgrupacion, ocAgrupacion, pedidosLoading } =
    usePedidos()
  const { ordenCompra } = useParams()
  const location = useLocation()
  const id = location.state

  useEffect(() => {
    const fetchOcAgrupacion = async () => {
      try {
        if (user && id) {
          getOcAgrupacion(id)
        }
      } catch (error) {
        setOcAgrupacion({})
        console.error('Error al obtener los pedidos: ', error)
      }
    }
    if (user.rol && cronograma?.id_cronograma) {
      fetchOcAgrupacion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, ordenCompra])

  return pedidosLoading ? (
    <Loading />
  ) : ocAgrupacion && ocAgrupacion.pedido ? (
    <SearchAndFilterTableProvider>
      <WrapperAgrupacionPage>
        <TableSection>
          <HeaderPedidoAgrupacion
            oc={{
              proveedor: ocAgrupacion.pedido.proveedor,
              tipoProveedor: ocAgrupacion.pedido.tipo_proveedor,
              ordenDeCompra: ordenCompra,
              estado: ocAgrupacion.pedido?.estado,
              estadoRecepcion: ocAgrupacion.pedido.estadoRecepcion,
              porcentajeRecepcion: ocAgrupacion.pedido.porcentajeRecepcion,
              totales: ocAgrupacion.pedido.totales,
              fecha: dayjs(ocAgrupacion.pedido.fechaConfeccion).format(
                'DD/MM/YYYY',
              ),
              idOcCompra: ocAgrupacion.pedido.id,
            }}
          />
          <ExpandableTables
            items={[
              {
                title: 'Pedidos Agrupación',
                component: (
                  <WrapperTables>
                    <PedidosAgrupadosTable
                      pedidos={ocAgrupacion.pedido.detalle}
                      totales={ocAgrupacion.pedido.totales}
                    />
                  </WrapperTables>
                ),
              },
              ...(user.rol.nombre === 'Coordinadora'
                ? [
                    {
                      title: 'Detalles de subasociadas',
                      component: (
                        <WrapperTables>
                          <DetallesSubasociadasTable
                            idOcCabecera={ocAgrupacion.pedido.id}
                            pedidos={ocAgrupacion.pedido.subasociadas}
                          />
                        </WrapperTables>
                      ),
                    },
                  ]
                : []),
            ]}
            openTables={['Pedidos Agrupacion']}
          />
        </TableSection>
      </WrapperAgrupacionPage>
    </SearchAndFilterTableProvider>
  ) : (
    <EmptyTableData>No hay datos</EmptyTableData>
  )
}

export default PedidosAgrupacionOrdenDeCompra
