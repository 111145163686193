import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const SearchAndFilterTableContext = createContext()

export const useSearchAndFilterTable = () =>
  useContext(SearchAndFilterTableContext)

export const SearchAndFilterTableProvider = ({
  children,
  initialValueData = [],
  searchParameter = 'nombre',
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState(initialValueData)
  const [filters, setFilters] = useState({})
  const memoizedInitialValueData = useMemo(
    () => initialValueData,
    [initialValueData],
  )

  useEffect(() => {
    const filterData = (data) => {
      if (!Array.isArray(data)) return []

      let filteredData = [...data]

      // Filtrar por categoría
      if (Object.keys(filters).length > 0) {
        if (filteredData.some((item) => Array.isArray(item?.articulos))) {
          if (!filters['todos']) {
            filteredData = filteredData.filter((categoria) => {
              const descripcion = categoria.descripcion
                ? categoria.descripcion
                    .toLowerCase()
                    .replace(/\s+/g, '_')
                    .replace(/[^\w-]/g, '')
                : ''

              return filters[descripcion]
            })
          }

          if (filters['activos'] || filters['suspendidos']) {
            const estados = []
            if (filters['activos']) estados.push('1')
            if (filters['suspendidos']) estados.push('31')

            filteredData = filteredData
              .map((item) => ({
                ...item,
                articulos: Array.isArray(item.articulos)
                  ? item.articulos.filter((articulo) =>
                      estados.includes(articulo.estado),
                    )
                  : [],
              }))
              .filter((item) => item.articulos.length > 0)
          }
        }
      }

      // Filtrar por búsqueda
      if (searchValue.trim() !== '') {
        if (filteredData.some((item) => Array.isArray(item?.articulos))) {
          filteredData = filteredData
            .map((item) => ({
              ...item,
              articulos: Array.isArray(item.articulos)
                ? item.articulos.filter((articulo) =>
                    articulo.descripcion
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase()),
                  )
                : [],
            }))
            .filter((item) => item.articulos.length > 0)
        } else {
          filteredData = filteredData.filter((ele) =>
            ele[searchParameter]
              ?.toLowerCase()
              ?.includes(searchValue.toLowerCase()),
          )
        }
      }

      return filteredData
    }
    const newData = filterData(memoizedInitialValueData)
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      setData(newData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, filters, searchParameter, memoizedInitialValueData])

  const setSearchInputValue = (value) => {
    setSearchValue(value)
  }

  const contextValues = {
    searchValue,
    setSearchInputValue,
    filters,
    setFilters,
    data,
  }

  return (
    <SearchAndFilterTableContext.Provider value={contextValues}>
      {children}
    </SearchAndFilterTableContext.Provider>
  )
}
