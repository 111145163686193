import React, { useState } from 'react'
import styled from 'styled-components'
import { P2, P3 } from 'components/Typography'
import { Button } from 'components/index'
import { useNavigate } from 'react-router-dom'
import ProgressBarComponent from 'components/ProgressBar/ProgressBar'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useProveedor } from 'contexts/ProveedorContext'
import { useCarrito } from 'contexts/CarritoContext'
import DescriptionRequerimientosProveedor from 'pages/comprar/SurtidoProveedores/components/DescriptionRequerimientosProveedor'
import Select from 'components/Select/Select'
import RotatingLoader from 'components/Loading/RotatingLoader'
import DropdownNotification from 'components/Notification/DropdownNotification'
import EditarPedidoAgrupacionTable from './EditarPedidoAgrupacionTable'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import HeaderInfo from '../../Control/components/HeaderInfo'

const ProgressBar = styled(ProgressBarComponent)`
  height: 10px;
  flex: none;
  width: 20%;
  margin-left: 30px;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const ExpandableBlockWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const EmptyTableData = styled(P3)`
  padding: 23px 30px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const CardsWrapper = styled.div`
  width: 100%;
`

const WrapperRequerimientos = styled.div`
  flex: 1;
  padding: 0 20px 20px;
`

const WrapperZona = styled.div`
  padding: 25px 20px 20px 20px;
`

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const WrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const CustomLoading = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  padding: 20px;
  margin: 30px;
  width: -webkit-fill-available;
`

const DropdownNotificationCustom = styled(DropdownNotification)`
  margin: 0 20px 20px;
`

const EditarPedidoTable = ({
  proveedor,
  matriz,
  selectedZona,
  setSelectedZona,
  goToNextStep,
  articulosDivididos,
  setArticulosDivididos,
  goToPrevStep,
  zonasProveedor,
}) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { bestZona, mejorProveedorZona, cambiarZona } = useCronograma()
  const { getProveedor, proveedorLoading } = useProveedor()
  const { getMatrizConsolidacion } = usePedidos()
  const { carritoAgrupado } = useCarrito()
  const [reqCalculados, setReqCalculados] = useState({})
  const [disabledConsolidar, setDisabledConsolidar] = useState([])
  const [totales, setTotales] = useState({})

  const formatInfo = (proveedor) => {
    return [
      {
        title: 'Requerimientos',
        componentTitle: (
          <ProgressBar
            progress={Math.min(reqCalculados, 100)}
            tipoProveedor={proveedor.tipo_proveedor}
          />
        ),
        component: (
          <WrapperDescription>
            <WrapperInfo>
              <WrapperRequerimientos>
                <DescriptionRequerimientosProveedor
                  requerimientos={proveedor.requerimientos}
                />
              </WrapperRequerimientos>
              {(user.rol.id_rol === '1' || user.rol.id_rol === '3') && (
                <WrapperZona>
                  <StyledLabel>Cambiar Zona</StyledLabel>
                  {zonasProveedor === null ? (
                    <P2>No hay zonas</P2>
                  ) : Object.keys(zonasProveedor).length > 0 ? (
                    <Select
                      id="Zona"
                      options={zonasProveedor}
                      disabled={proveedor.tipo_proveedor === '1'}
                      bestZona={bestZona ? bestZona[proveedor.id] : false}
                      selectedOption={
                        proveedor.tipo_proveedor === '1' ? 1 : selectedZona
                      }
                      toggleOptionsAction={() =>
                        mejorProveedorZona(proveedor.id)
                      }
                      onChange={async (zonaId) => {
                        //TODO: MEJORAR
                        await cambiarZona(
                          zonaId,
                          proveedor.id,
                          carritoAgrupado.cabecera.id,
                        )
                        setSelectedZona(zonaId)
                        const prove = await getProveedor(proveedor.id)
                        await getMatrizConsolidacion(
                          prove.id,
                          prove.zonaDefault,
                        )
                      }}
                    />
                  ) : (
                    <CustomLoading>
                      <RotatingLoader />
                    </CustomLoading>
                  )}
                </WrapperZona>
              )}
            </WrapperInfo>
            {proveedor.tipo_proveedor === '1' ? (
              <DropdownNotificationCustom
                text="No está disponible el cambio de zonas en las Marcas Líderes."
                type="notificacion"
              />
            ) : (
              user.rol.id_rol === '1' ||
              (user.rol.id_rol === '3' && (
                <DropdownNotificationCustom
                  text="Las zonas de reparto más recomendadas para tu ubicación
               aparecerán con una estrella amarilla en el menú
               desplegable"
                  type="notificacion"
                />
              ))
            )}
          </WrapperDescription>
        ),
      },
      {
        title: 'Pedido',
        component: proveedorLoading ? (
          <CustomLoading>
            <RotatingLoader />
          </CustomLoading>
        ) : (
          <ExpandableBlockWrapper>
            {matriz?.result?.length === 0 ? (
              <EmptyPage>
                <EmptyTableData>No hay productos en esta zona</EmptyTableData>
                <ButtonIrAComprar
                  onClick={() => navigate('/comprar')}
                  type="secondary"
                  text="Ir a Comprar"
                  size="small"
                />
              </EmptyPage>
            ) : (
              proveedor.requerimientos && (
                <EditarPedidoAgrupacionTable
                  requerimientos={proveedor?.requerimientos}
                  matriz={matriz}
                  proveedor={proveedor}
                  reqCalculados={reqCalculados}
                  setReqCalculados={setReqCalculados}
                  goToNextStep={goToNextStep}
                  articulosDivididos={articulosDivididos}
                  setArticulosDivididos={setArticulosDivididos}
                  goToPrevStep={goToPrevStep}
                  setDisabledConsolidar={setDisabledConsolidar}
                  totales={totales}
                  setTotales={setTotales}
                />
              )
            )}
          </ExpandableBlockWrapper>
        ),
      },
    ]
  }

  return (
    <Wrapper>
      <HeaderInfo
        proveedor={proveedor}
        totales={{
          ...totales,
          requerimiento: Math.min(reqCalculados, 100) || null,
        }}
      />
      <CardsWrapper>
        <ExpandableTables
          items={formatInfo(proveedor)}
          openTables={['Requerimientos', 'Pedido']}
        />
      </CardsWrapper>
      {disabledConsolidar.includes('req') && (
        <DropdownNotification
          text="Revisá los requerimientos mínimos del proveedor para continuar la consolidación"
          type={proveedor.tipo_proveedor === '1' ? 'error' : 'alerta'}
        />
      )}
      {disabledConsolidar.includes('mult') && (
        <DropdownNotification
          text="Controlá los múltiplos de los productos para continuar la consolidación."
          type={proveedor.tipo_proveedor === '1' ? 'error' : 'alerta'}
        />
      )}

      <TableFooter>
        <Button
          type="tertiary"
          text="Volver al paso 1"
          onClick={goToPrevStep}
        />
        <Button
          type="primary"
          text="Siguiente"
          disabled={
            proveedor.tipo_proveedor === '1' && disabledConsolidar.length > 0
          }
          onClick={goToNextStep}
        />
      </TableFooter>
    </Wrapper>
  )
}

export default EditarPedidoTable
