import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import CurrencyFormat from 'react-currency-format'
import { Download } from 'feather-icons-react/build/IconComponents'
import { usePedidos } from 'contexts/PedidosContext'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const WrapperTable = styled.div`
  max-height: 300px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
`

const DownloadIcon = styled(Download)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
`

const formatData = (facturas, obtenerPdfFactura) => {
  const onClickFacturaDescarga = async (
    idFactura,
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado,
  ) => {
    const pdfData = await obtenerPdfFactura(
      idFactura,
      idOcCabecera,
      idCabeceraRecepcion,
      idAfiliado,
    )
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `Factura-ID-${idFactura}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return facturas?.map((factura) => ({
    tipo: factura.abreviatura,
    letra: factura.letra,
    numero: factura.nrocomp,
    fecha: factura.fecha,
    neto: (
      <CurrencyFormat
        value={factura?.neto?.replace('.', ',')}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    iva: (
      <CurrencyFormat
        value={factura?.iva?.replace('.', ',')}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    apo: (
      <CurrencyFormat
        value={factura?.regalia?.replace('.', ',')}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    imp: (
      <CurrencyFormat
        value={factura?.impuestos_internos?.replace('.', ',')}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    total_facturado: (
      <CurrencyFormat
        value={factura?.total_facturado?.replace('.', ',')}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    descarga: (
      <DownloadIcon
        onClick={() => onClickFacturaDescarga(factura?.id_factura)}
      />
    ),
  }))
}

const headers = [
  {
    name: 'Tipo de Comp.',
    isOrderable: false,
  },
  {
    name: 'Letra',
    isOrderable: false,
  },
  {
    name: 'N° comp.',
    isOrderable: false,
  },
  {
    name: 'Fecha',
    isOrderable: false,
  },
  {
    name: 'Neto',
    isOrderable: false,
  },
  {
    name: 'IVA',
    isOrderable: false,
  },
  {
    name: 'Aporte',
    isOrderable: false,
  },
  {
    name: 'Imp. internos',
    isOrderable: false,
  },
  {
    name: 'Total Facturado',
    isOrderable: false,
  },
  {
    name: '',
    isOrderable: false,
  },
]

const FacturasTable = ({ facturas }) => {
  const { obtenerPdfFactura } = usePedidos()
  const tableFormat = formatData(facturas, obtenerPdfFactura)

  return (
    <WrapperTable>
      {facturas?.length > 0 ? (
        <Table
          data={tableFormat}
          headers={headers}
          gridTemplate="6% 6% 12% 10% 10% 8% 8% 8% 8% 4%"
        />
      ) : (
        <EmptyTableData>
          Todavía no se ha generado la factura. En breve vas a poder
          visualizarla.
        </EmptyTableData>
      )}
    </WrapperTable>
  )
}

export default FacturasTable
