import React from 'react'
import { useNavigate } from 'react-router-dom'

import { H2, H4 } from 'components/Typography'
import { Button } from 'components/index'
import styled from 'styled-components'
import {
  ChevronsLeft,
  ChevronsRight,
} from 'feather-icons-react/build/IconComponents'

const WrapperMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  color: ${({ theme }) => theme.colors.neutral.white};
`

const Title = styled(H2)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.neutral.white};
  border-color: ${({ theme }) => theme.colors.neutral.white};
  margin-top: 12px;
`

const StyledH2 = styled(H2)`
  display: inline-block;
`

const MessageWithAction = ({
  title,
  subtitle,
  navigateTo,
  action,
  textButton,
}) => {
  const navigate = useNavigate()
  return (
    <WrapperMessage>
      <Title>
        <ChevronsRight strokeWidth="3" size={38} />
        <StyledH2>{title}</StyledH2>
        <ChevronsLeft strokeWidth="3" size={38} />
      </Title>
      <H4>{subtitle}</H4>
      <StyledButton
        type="secondary"
        size="large"
        text={textButton}
        onClick={navigateTo ? () => navigate(navigateTo) : action}
      />
    </WrapperMessage>
  )
}

export default MessageWithAction
