import React from 'react'
import styled from 'styled-components'

const StyledFooterCopyright = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

const CopyrightText = styled.p`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.black};
  text-wrap: balance;
  color: white;
  font-size: 12px;
  margin: 0;
  padding: 12px;

  @media (max-width: 440px) {
    padding: 8px;
  }
`

const FooterCopyright = () => {
  return (
    <StyledFooterCopyright>
      <CopyrightText>
        © Copyright Central Obrera de Compras F.A.C.C / Todos los derechos
        reservados a Central Obrera de compras F.A.C.C / Dirección General de
        Defensa y Protección al Consumidor: para consultas y/o denuncias ingrese
        aquí. Términos y condiciones
      </CopyrightText>
    </StyledFooterCopyright>
  )
}

export default FooterCopyright
