import RotatingLoader from 'components/Loading/RotatingLoader'
import { showToast } from 'components/Notification/ToastNotification'
import { Minus, Plus } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 2px solid ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 20px;
  padding: 8px;
  gap: 5px;
`

const NumberDisplay = styled.input`
  border: none;
  outline: none;
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  background-color: unset;
  font-size: 16px;
  width: 30px;
  text-align: center;
  color: ${({ theme, disabled, error }) => {
    return disabled
      ? theme.colors.neutral.lightGray
      : error
        ? theme.colors.alarmsStatuses.red
        : theme.colors.neutral.black
  }};

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`

const ButtonMinus = styled(Minus)`
  background-color: ${({ theme, $disabled }) => {
    return $disabled
      ? theme.colors.neutral.lightGray
      : theme.colors.primary.blue
  }};
  color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 12px;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

const ButtonPlus = styled(Plus)`
  background-color: ${({ theme, $disabled }) => {
    return $disabled
      ? theme.colors.neutral.lightGray
      : theme.colors.primary.blue
  }};
  color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 12px;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

const LoaderContainer = styled.div`
  padding-bottom: 2px;
`

const CustomRotatingLoader = styled(RotatingLoader)`
  width: 40px;
`

const CounterInput = ({
  amount,
  onClick,
  className,
  multiplo = 1,
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(amount)
  const [timer, setTimer] = useState(null) // Estado para mantener el temporizador
  const [error, setError] = useState(false)

  useEffect(() => {
    const formatValue = String(amount)
    if (formatValue !== undefined && formatValue !== 0) {
      let formattedValue = formatValue.replace(/^0+(?=\d)/, '')
      setCount(formattedValue)
    } else {
      setCount(0)
    }
  }, [amount])

  const handleChange = (value) => {
    setError(false)

    const formatValue = String(value)
    if (formatValue !== undefined && formatValue !== 0) {
      let formattedValue = formatValue.replace(/^0+(?=\d)/, '')
      setCount(formattedValue)
    } else {
      setCount(0)
    }

    clearTimeout(timer)
    const newTimer = setTimeout(async () => {
      if (multiplo !== 1 && formatValue % multiplo !== 0) {
        setError(true)
        showToast(`Por favor utilizá multiplos de ${multiplo}`, 'error')
        return
      } else {
        // Usar nuevo temporizador
        setLoading(true)
        await onClick(formatValue)
        setLoading(false)
      }
    }, 2000)
    setTimer(newTimer) // Actualizar el estado del temporizador
  }

  const increment = () => {
    if (!disabled) {
      const newValue = parseInt(count) + (multiplo ? multiplo : 1)
      handleChange(newValue)
    }
  }

  const decrement = () => {
    if (!disabled) {
      const newValue =
        parseInt(count) === 0 ? 0 : parseInt(count) - (multiplo ? multiplo : 1)
      handleChange(newValue)
    }
  }

  return (
    <CounterContainer className={className}>
      <ButtonMinus
        size={18}
        onClick={decrement}
        $disabled={disabled || parseInt(count) === 0}
      />
      {loading ? (
        <LoaderContainer>
          <CustomRotatingLoader />
        </LoaderContainer>
      ) : (
        <NumberDisplay
          type="number"
          value={count}
          onChange={(e) =>
            handleChange(e.target.value === '' ? 0 : e.target.value)
          }
          disabled={disabled}
          error={error}
        />
      )}
      <ButtonPlus size={18} onClick={increment} $disabled={disabled} />
    </CounterContainer>
  )
}

export default CounterInput
