import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'components/layout/FlexComponents'
import { H2, P2 } from 'components/Typography'
import SearchInput from 'components/SearchBar/SearchInput'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'

const Header = styled(Row)`
  display: flex;
  box-sizing: border-box;
  margin: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  padding: 6px 30px;
`

const HeaderText = styled(Col)`
  margin: 12px 0 0 24px;
  gap: 8px;
`

const FechaHastaText = styled(P2)`
  margin-top: -6px;
`

const SearchTableInput = styled(SearchInput)`
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  color: ${({ theme }) => theme.colors.primary.lighterBlue};
  border: 3px solid ${({ theme }) => theme.colors.primary.darkBlue};
  width: auto;
`

const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const TableHeader = ({ title, windowTo, cronogramaNumber }) => {
  const { searchValue, setSearchInputValue } = useSearchAndFilterTable()

  const handleSearchInputChange = (e) => {
    setSearchInputValue(e.target.value)
  }

  return (
    <Header>
      <Description>
        <HeaderText>
          <H2>{title}</H2>
          <FechaHastaText>{windowTo}</FechaHastaText>
        </HeaderText>
        <P2>{cronogramaNumber}</P2>
      </Description>
      <SearchTableInput
        value={searchValue}
        onChange={handleSearchInputChange}
        placeholder="Buscar proveedor"
      />
    </Header>
  )
}

export default TableHeader
