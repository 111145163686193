import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString } from 'utils/textProcess'

const ArticulosService = {
  getArticulos: async ({ idCronograma, idProveedor, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulos?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        console.error(error)
      })
  },
  getArticulo: async ({ idArticulo, idCarrito, idCronograma, token }) => {
    let data = {
      id_articulo: parseInt(idArticulo),
      id_carrito: parseInt(idCarrito),
      id_cronograma: parseInt(idCronograma),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulo?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        console.error(error)
      })
  },
  getPromocion: async ({ idCronograma, idProveedor, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulos-promocion?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data.articulos
      })
      .catch((error) => {
        console.error(error)
      })
  },
  getPromociones: async ({ idCronograma, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/promociones?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        console.error(error)
      })
  },
}

export default ArticulosService
