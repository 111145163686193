import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import circleImg from 'assets/circulo_home.png'
import { useProveedor } from 'contexts/ProveedorContext'
import PedidosParticipacionPage from './Control/PedidosParticipacionPage'
import EditarPedido from './Editar/EditarPedido'
import StepConsolidatePedido from './Consolidar/StepConsolidatePedido'
import StepCard from 'pages/pedidos/components/StepCard'
import { useLocation } from 'react-router-dom'
import { useCronograma } from 'contexts/CronogramaContext'
import { useAuth } from 'contexts/AuthContext'
import { usePedidos } from 'contexts/PedidosContext'
import { ModalProvider } from 'contexts/ModalContext'

const RecepcionPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ImageOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 35vh;
  filter: brightness(93%);
  z-index: -999999;
`
const StepsWrapper = styled.div`
  z-index: 2;
  margin-top: 6vh;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const TableWrapper = styled.div`
  margin: 20px 10%;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const CosolidacionPage = () => {
  const location = useLocation()
  const idProveedor = location.state?.idProveedor
  const { cronograma, getZonasProveedor } = useCronograma()
  const { user, token } = useAuth()
  const { getProveedor, currentProveedor } = useProveedor()
  const { participacion, getParticipacion } = usePedidos()
  const [activeStep, setActiveStep] = useState(1)
  const [articulosDivididos, setArticulosDivididos] = useState([])
  const [zonasProveedor, setZonasProveedor] = useState([])

  useEffect(() => {
    const fetchZonasParticipacion = async () => {
      !currentProveedor.id && (await getProveedor(idProveedor))
      await getParticipacion(idProveedor)

      const zonas = await getZonasProveedor(idProveedor)
      setZonasProveedor(zonas)
    }

    if (idProveedor && user.rol && cronograma?.id_cronograma) {
      fetchZonasParticipacion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])

  const goToNextStep = () => {
    if (activeStep < 3) setActiveStep(activeStep + 1)
  }

  const goToPrevStep = () => {
    if (activeStep !== 0) setActiveStep(activeStep - 1)
  }

  const steps = [
    {
      title: 'Control de participación',
      description: 'Controlar la participación del pedido',
    },
    {
      title: 'Editar pedido',
      description: 'Controlar y ajustar cantidades',
    },
    {
      title: 'Consolidar pedido',
      description: 'Cerrar pedido consolidandolo',
    },
  ]

  let stepContent
  switch (activeStep) {
    case 1:
      stepContent = (
        <PedidosParticipacionPage
          goToNextStep={goToNextStep}
          idProveedor={idProveedor}
        />
      )
      break
    case 2:
      stepContent = (
        <EditarPedido
          proveedor={{
            ...currentProveedor,
            requisitosCumplidos: participacion?.proveedor.requisitosCumplidos,
            requerimientos: participacion?.proveedor.requerimientos,
            afiliados: participacion?.proveedor.afiliados,
            totales: participacion?.proveedor?.totales,
          }}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
          articulosDivididos={articulosDivididos}
          setArticulosDivididos={setArticulosDivididos}
          zonasProveedor={zonasProveedor}
        />
      )
      break
    case 3:
      stepContent = (
        <ModalProvider>
          <StepConsolidatePedido
            proveedor={{
              ...currentProveedor,
              requisitosCumplidos: participacion?.proveedor.requisitosCumplidos,
              requerimientos: participacion?.proveedor.requerimientos,
              afiliados: participacion?.proveedor.afiliados,
              totales: participacion?.proveedor?.totales,
            }}
            afiliados={articulosDivididos}
            goToPrevStep={goToPrevStep}
            zonasProveedor={zonasProveedor}
          />
        </ModalProvider>
      )
      break
  }

  return (
    <RecepcionPageWrapper>
      <ImageOverlay src={circleImg} alt="Logo" />
      <StepsWrapper>
        {steps.map((step, index) => (
          <StepCard
            key={index}
            cardData={step}
            index={index}
            activeStep={activeStep}
          />
        ))}
      </StepsWrapper>
      <TableWrapper>{stepContent}</TableWrapper>
    </RecepcionPageWrapper>
  )
}

export default CosolidacionPage
