import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.12px;
`

const BtnL = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default BtnL
