import React, { useState } from 'react'
import styled from 'styled-components'

import { BtnS } from 'components/Typography'
import Table from 'components/Table/Table'
import { Row } from 'components/layout/FlexComponents'
import Link from 'components/Button/LinkUnderline'
import { Button } from 'components/index'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'contexts/ModalContext'
import { replaceText } from 'utils/textProcess'
import ConfirmComprarEnCeroModal from 'components/Modal/ConfirmComprarEnCeroModal'
import { useAuth } from 'contexts/AuthContext'
import StatusTag from 'components/Tag/StatusTag'
import { useCarrito } from 'contexts/CarritoContext'
import CancelarComprarEnCeroModal from 'components/Modal/CancelarComprarEnCeroModal'
import { useProveedores } from 'contexts/ProveedoresContext'
import RotatingLoading from 'components/Loading/Loading'

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const LinkTable = styled(Link)`
  font-size: 14px;
  margin-left: auto;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const TableWrapper = styled.div`
  padding: 18px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    padding: 8px;
  }
`

const ButtonCellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const traducir = (palabra, habilitado, user) => {
  const messages = {
    'El proveedor se encuentra en consolidación': 'Consolidado',
    'Proveedor Exceptuado para el afiliado': 'Deshabilitado',
    'Proveedor de Marca Lider no habilitado para este afiliado':
      'Deshabilitado',
    'Este usuario ya posee un carrito confeccionado para este proveedor':
      'Confeccionado',
    'El cronograma no se encuentra habilitado': 'Deshabilitado',
    'Proveedor no habilitado en este cronograma': 'Deshabilitado',
    'Este usuario posee un carrito cargado en cero': 'Confirmado en 0',
  }
  if (habilitado === 0) {
    return 'Deshabilitado'
  }
  if (!palabra) {
    return 'Habilitado'
  }
  const palabraTraducida = Object.keys(messages).find((key) =>
    palabra.includes(key),
  )
  if (user.rol.id_rol == '3') {
    return palabraTraducida
      ? messages[palabraTraducida] == 'Confeccionado'
        ? 'Consolidado'
        : 'Sin estado'
      : 'Sin estado'
  }
  return palabraTraducida ? messages[palabraTraducida] : 'Sin estado'
}

const formatData = (
  data,
  onClickComprarProveedores,
  onClickConfirmar,
  rol,
  carritoAgrupado,
  user,
  width,
) =>
  data?.map((proveedor) => ({
    Estado:
      carritoAgrupado !== undefined &&
      carritoAgrupado?.resumenDetalle &&
      Object.keys(carritoAgrupado?.resumenDetalle).length > 0 &&
      carritoAgrupado.resumenDetalle[proveedor.id] ? (
        <StatusTag estado="En curso" />
      ) : (
        <StatusTag
          estado={traducir(
            proveedor.motivoPuedePedir,
            proveedor.habilitado,
            user,
          )}
        />
      ),
    Proveedores: (
      <ProveedoresItem key={proveedor.nombre_comercial}>
        <BtnS>{proveedor.nombre_comercial}</BtnS>
      </ProveedoresItem>
    ),
    ...(width > 440 && {
      Comprar:
        rol.id_rol !== '3' ? (
          proveedor.habilitado === 1 && proveedor.puedePedir ? (
            <LinkTable onClick={() => onClickConfirmar(proveedor.id)}>
              Comprar en otra ocasión
            </LinkTable>
          ) : proveedor.motivoPuedePedir.includes(
              'Este usuario posee un carrito cargado en cero',
            ) ? (
            <LinkTable onClick={() => onClickConfirmar(proveedor.id, true)}>
              Quiero volver a comprar
            </LinkTable>
          ) : (
            <LinkTable></LinkTable>
          )
        ) : (
          <LinkTable></LinkTable>
        ),
    }),
    Habilitar: (
      <ButtonCellContainer>
        {proveedor.habilitado === 1 && proveedor.puedePedir ? (
          <Button
            text="Comprar"
            onClick={() =>
              onClickComprarProveedores(
                proveedor.id,
                replaceText(proveedor.nombre_comercial, ' ', '-'),
              )
            }
          />
        ) : (
          <Button
            text="Ver surtido"
            type="secondary"
            onClick={() =>
              onClickComprarProveedores(
                proveedor.id,
                replaceText(proveedor.nombre_comercial, ' ', '-'),
              )
            }
          />
        )}
      </ButtonCellContainer>
    ),
  }))

const ProveedoresCompraTable = ({ data, resetFilter, width }) => {
  const { openModal, isModalOpen } = useModal()
  const { user } = useAuth()
  const [idProveedor, setIdProveedor] = useState(null)
  const [isCancelar, setIsCancelar] = useState(false)
  const { carritoAgrupado } = useCarrito()
  const navigate = useNavigate()
  const { proveedoresLoading } = useProveedores()

  const headers = [
    {
      name: 'Estado',
      isOrderable: false,
    },
    {
      name: 'Proveedores',
      isOrderable: false,
    },
    ...(width > 430
      ? [
          {
            name: '',
            isOrderable: false,
          },
        ]
      : []),
    {
      name: '',
      isOrderable: false,
    },
  ]

  const onClickComprarProveedores = async (idProveedor, nombreProveedor) => {
    navigate(
      `/comprar/${replaceText(nombreProveedor, ' ', '-')}/id/${idProveedor}`,
    )
  }

  const onClickConfirmar = (idProveedor, isCancelar) => {
    setIdProveedor(idProveedor)
    setIsCancelar(isCancelar)
    openModal()
  }

  const { rol } = user

  return (
    <TableWrapper>
      {proveedoresLoading ? (
        <RotatingLoading />
      ) : data?.length > 0 ? (
        <Table
          data={formatData(
            data,
            onClickComprarProveedores,
            onClickConfirmar,
            rol,
            carritoAgrupado,
            user,
            width,
          )}
          headers={headers}
          numerate={false}
          gridTemplate={width > 440 ? '20% 30% 27% 20%' : '22% 32% 20%'}
          gap={width > 440 ? '15' : '32'}
        />
      ) : (
        <EmptyTableData>
          No se encontraron proveedores que contengan el texto
        </EmptyTableData>
      )}
      {isModalOpen &&
        (isCancelar ? (
          <CancelarComprarEnCeroModal
            idProveedor={idProveedor}
            resetFilter={resetFilter}
          />
        ) : (
          <ConfirmComprarEnCeroModal
            idProveedor={idProveedor}
            resetFilter={resetFilter}
          />
        ))}
    </TableWrapper>
  )
}

export default ProveedoresCompraTable
