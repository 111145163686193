import React from 'react'
import styled from 'styled-components'

const BTN_SIZES_STYLES = {
  large: {
    fontSize: '16px',
    padding: '16px 34px',
  },
  medium: {
    fontSize: '14px',
    padding: '8px 30px',
  },
  small: {
    fontSize: '12px',
    padding: '4px 20px',
  },
}

const getButtonStyles = (theme) => ({
  primary: {
    default: {
      backgroundColor: theme.colors.primary.blue,
      color: theme.colors.neutral.white,
      border: `2px solid ${theme.colors.primary.blue}`,
    },
    disabled: {
      backgroundColor: theme.colors.primary.lighterBlue,
      border: `2px solid ${theme.colors.primary.lighterBlue}`,
      color: theme.colors.neutral.white,
    },
    // focused: {
    // },
    // hover: {
    // },
    // active: {
    // },
  },
  secondaryNoBorder: {
    default: {
      backgroundColor: theme.colors.neutral.white,
      color: theme.colors.primary.blue,
      border: 'none',
    },
    disabled: {
      backgroundColor: theme.colors.neutral.white,
      color: theme.colors.primary.lightBlue,
      border: 'none',
    },
  },
  secondary: {
    default: {
      backgroundColor: 'transparent',
      color: theme.colors.primary.blue,
      border: `2px solid ${theme.colors.primary.blue}`,
    },
    disabled: {
      backgroundColor: theme.colors.neutral.white,
      border: `2px solid ${theme.colors.primary.lightBlue}`,
      color: theme.colors.primary.lightBlue,
    },
    // focused: {
    // },
    // hover: {
    // },
    // active: {
    // },
  },
  tertiary: {
    default: {
      backgroundColor: 'transparent',
      color: theme.colors.primary.blue,
      border: `none`,
      textDecoration: 'underline',
    },
    disabled: {
      backgroundColor: 'transparent',
      color: theme.colors.primary.lightBlue,
      border: `none`,
      textDecoration: 'underline',
    },
    // focused: {
    // },
    // hover: {
    // },
    // active: {
    // },
  },
  contrast: {
    default: {
      backgroundColor: 'transparent',
      color: theme.colors.neutral.white,
      border: `2px solid ${theme.colors.neutral.white}`,
    },
    disabled: {
      backgroundColor: 'transparent',
      color: theme.colors.neutral.white,
      border: `2px solid ${theme.colors.neutral.white}`,
    },
    // focused: {
    // },
    // hover: {
    // },
    // active: {
    // },
  },
})

const StyledButton = styled.button`
  width: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: ${({ theme, type }) =>
    getButtonStyles(theme)[type].default.textDecoration};
  font-size: ${({ size }) => BTN_SIZES_STYLES[size].fontSize};
  font-weight: 600;
  line-height: normal;
  border-radius: 40px;
  padding: ${({ size }) => BTN_SIZES_STYLES[size].padding};
  background-color: ${({ theme, type }) =>
    getButtonStyles(theme)[type].default.backgroundColor};
  border: ${({ theme, type }) => getButtonStyles(theme)[type].default.border};
  color: ${({ theme, type }) => getButtonStyles(theme)[type].default.color};
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &[disabled] {
    background-color: ${({ theme, type }) =>
      getButtonStyles(theme)[type].disabled.backgroundColor};
    border: ${({ theme, type }) =>
      getButtonStyles(theme)[type].disabled.border};
    color: ${({ theme, type }) => getButtonStyles(theme)[type].disabled.color};
    text-decoration: ${({ theme, type }) =>
      getButtonStyles(theme)[type].disabled.textDecoration};
    cursor: default;
  }

  @media (max-width: 440px) {
    padding: 8px 14px;
    font-size: 14px;
  }
`

const Button = ({
  className,
  text,
  icon,
  iconAfter,
  size = 'medium',
  type = 'primary',
  disabled,
  onClick,
}) => {
  const handleClick = () => {
    if (!disabled && onClick && typeof onClick === 'function') {
      onClick()
    }
  }
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      size={size}
      type={type}
      onClick={handleClick}
    >
      {icon}
      {text}
      {iconAfter}
    </StyledButton>
  )
}

export default Button
