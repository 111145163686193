import React from 'react'
import styled from 'styled-components'

import { FilterComponent } from 'components/FilterComponent/FilterComponent'
import SearchInput from 'components/SearchBar/SearchInput'

const FilterWrapper = styled.div`
  min-width: 20%;
`

const SearchTableInput = styled(SearchInput)`
  color: ${({ theme }) => theme.colors.primary.lighterBlue};
  border: 3px solid ${({ theme }) => theme.colors.primary.darkBlue};
  width: auto;
  margin: 6px 0 14px 0;
`

const FilterSection = ({
  searchValue,
  setSearchValue,
  filters,
  setFilters,
}) => {
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value)
  }

  return (
    <FilterWrapper>
      <SearchTableInput
        value={searchValue}
        onChange={handleSearchInputChange}
        placeholder="Buscar proveedor"
      />
      <FilterComponent
        setFilters={setFilters}
        filters={filters}
        onlyOneOption={true}
        filtersOptions={{
          Proveedores: [
            {
              name: 'Todos los proveedores',
              tag: 'Todos',
              key: 'todos_provedores',
              default: true,
            },
            {
              name: 'Activos en cronograma actual',
              tag: 'Cronograma actual',
              key: 'cronograma_actual',
            },
          ],
          'Tipos de proveedores': [
            {
              name: 'Todos los proveedores',
              tag: 'Todos',
              key: 'directa_y_redistribucion',
              default: true,
            },
            {
              name: 'Operación directa',
              tag: 'Operación directa',
              key: 'operación_directa',
            },
            {
              name: 'Marcas líderes',
              tag: 'Marcas líderes',
              key: 'marcas_líderes',
            },
          ],
        }}
      />
    </FilterWrapper>
  )
}

export default FilterSection
