import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Wrapper } from 'components/layout/FlexComponents'
import Loading from 'components/Loading/Loading'
import { useProveedor } from 'contexts/ProveedorContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import EditarPedidoTable from './components/EditarPedidoTable'

const WrapperEditarPedidoPage = styled(Wrapper)`
  display: flex;
  gap: 50px;
  box-sizing: border-box;
`

const EditarPedido = ({
  goToNextStep,
  setArticulosDivididos,
  articulosDivididos,
  goToPrevStep,
  proveedor,
  zonasProveedor,
}) => {
  const { pedidosLoading } = usePedidos()
  const { proveedorLoading } = useProveedor()
  const { getMatrizConsolidacion, matrizConsolidacion } = usePedidos()
  const { user } = useAuth()
  const [selectedZona, setSelectedZona] = useState()

  useEffect(() => {
    const fetchMatrizProveedor = async () => {
      await getMatrizConsolidacion(proveedor.id, proveedor.zonaDefault)
      if (user.rol.id_rol === '1' || user.rol.id_rol === '3')
        setSelectedZona(proveedor.zonaDefault)
    }
    if (proveedor) {
      fetchMatrizProveedor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapperEditarPedidoPage>
      {proveedorLoading ||
      pedidosLoading ||
      !proveedor?.id ||
      articulosDivididos.length > 0 ||
      !matrizConsolidacion ? (
        <Loading />
      ) : (
        <EditarPedidoTable
          proveedor={proveedor}
          matriz={matrizConsolidacion}
          selectedZona={selectedZona}
          setSelectedZona={setSelectedZona}
          goToNextStep={goToNextStep}
          articulosDivididos={articulosDivididos}
          setArticulosDivididos={setArticulosDivididos}
          goToPrevStep={goToPrevStep}
          zonasProveedor={zonasProveedor}
        />
      )}
    </WrapperEditarPedidoPage>
  )
}

export default EditarPedido
