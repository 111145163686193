import React from 'react'
import styled from 'styled-components'
import Tag from 'components/Tag/Tag'
import Button from 'components/Button/Button'
import { H3, H4 } from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import {
  capitalizeFirstLetter,
  replaceText,
  textoCapitalizado,
} from 'utils/textProcess'

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 192px;
  height: 244px;
  margin: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:first-child {
    margin-left: 0;
  }
`

const CardImage = styled.div`
  width: 100%;
  /* La suma de alturas de CardImage y CardBody debe ser 100% */
  height: 30%;
  background: url(${({ $image }) => $image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${({ theme }) => theme.colors.primary.lightBlue};
  /* La suma de alturas de CardImage y CardBody debe ser 100% */
  height: 70%;
  justify-content: space-between;
  gap: 8px;
  margin-top: 10px;
  width: 100%;
`

const TagContainer = styled.div`
  display: inline-flex;
  gap: 6px;
  position: relative;
  margin-bottom: 20px;
`

const CardTitle = styled(H3)`
  font-size: 14px;
  margin-top: 18px;
  font-family: 'Roboto-bold';
  line-height: 1;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const CardSubTitle = styled(H4)`
  font-size: 14px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.neutral.black};
  font-family: 'Roboto';
`

const CardButton = styled(Button)`
  display: flex;
  align-self: flex-end;
  margin-left: auto;
`

const FeaturedTag = styled(Tag)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-family: 'Roboto-bold';
  font-size: 10px;
  text-transform: uppercase;
`

const PromoCard = ({ card, cronogramaHabilitado }) => {
  const firstThreeTags = card.botTags ? card.botTags.slice(0, 3) : []
  const navigate = useNavigate()
  return (
    <StyledCard>
      <CardImage $image={card.path_thumb} />
      {card.topTag && <FeaturedTag type="tertiary">{card.topTag}</FeaturedTag>}
      <CardBody>
        <CardTitle>{capitalizeFirstLetter(card.title.toLowerCase())}</CardTitle>
        <CardSubTitle>{textoCapitalizado(card?.proveedor || '')}</CardSubTitle>
        {card.botTags && (
          <TagContainer>
            {firstThreeTags.map((tag) => (
              <Tag key={tag.id} type={tag.type}>
                {tag.text}
              </Tag>
            ))}
          </TagContainer>
        )}
        <CardButton
          type={cronogramaHabilitado ? 'primary' : 'secondary'}
          text={cronogramaHabilitado ? 'Comprar' : 'Ver'}
          size="small"
          onClick={() =>
            navigate(
              `/comprar/${replaceText(card.proveedor, ' ', '-')}/${card.id}`,
            )
          }
        ></CardButton>
      </CardBody>
    </StyledCard>
  )
}

export default PromoCard
