import Navbar from 'components/layout/navbar/Navbar'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Footer from 'components/layout/footer/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from 'components/Loading/Loading'
import { Toaster } from 'react-hot-toast'
import { withTheme } from 'styled-components'
import Error from './Error'
import { useInitialData } from 'contexts/InitialDataContext'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  margin-top: ${({ $isLogin }) => ($isLogin ? '0' : '24px')};
`

const Layout = ({ children, theme, dontHandleRoutes }) => {
  const location = useLocation()
  const { errorLoadingCronograma, errorLoadingData, loading } = useInitialData()
  const isLogin =
    location.pathname === '/login' || location.pathname === '/reset-password'
  const navigate = useNavigate()

  useEffect(() => {
    const handleRouteChange = () => {
      // Recupera la posición de scroll de la tabla de comprar después de entrar en detalle de producto
      // y volver a la vista de comprar/:nombreProveedor

      // Recupera la página actual en la tabla de comprar después de entrar en ver surtido o en comprar
      // (en  caso de volver inmediatamente después a la vista comprar)
      let prevRouteWasProducto = false
      let prevRouteWasComprarAProveedor = false
      if (location.pathname.includes('/producto/')) {
        prevRouteWasProducto = true
      }
      if (
        !location.pathname.includes('/comprar/' || !prevRouteWasProducto) &&
        !location.pathname.includes('/producto/')
      ) {
        localStorage.removeItem('productosTableScrollPosition')
      }
      if (
        !location.pathname.includes('/comprar') ||
        (!prevRouteWasComprarAProveedor &&
          !location.pathname.includes('/comprar'))
      ) {
        localStorage.removeItem('comprarPageNumber')
      }
      if (location.pathname.includes('/comprar/')) {
        prevRouteWasComprarAProveedor = true
      }
      // Hace Scroll hacia arriba en cualquier cambio de ruta
      window.scrollTo(0, 0)
    }

    handleRouteChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname])

  return (
    <PageContainer>
      <div>
        <Toaster
          position="bot-left"
          toastOptions={{
            duration: 4000,
            style: {
              padding: '12px 16px',
              fontWeight: 400,
            },
            success: {
              iconTheme: {
                primary: theme.colors.alarmsStatuses.green,
              },
            },
            error: {
              iconTheme: {
                primary: theme.colors.alarmsStatuses.red,
              },
            },
          }}
        />
      </div>
      <Navbar dontHandleRoutes={dontHandleRoutes} />
      <Main $isLogin={isLogin} $isLoading={loading}>
        {errorLoadingCronograma && errorLoadingData ? (
          <Error isCronogramaError={true} />
        ) : errorLoadingData ? (
          <Error />
        ) : loading ? (
          <Loading />
        ) : (
          children
        )}
      </Main>
      <Footer />
    </PageContainer>
  )
}

const LayoutWithTheme = withTheme(Layout)

export default LayoutWithTheme
