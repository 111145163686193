import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import { H1 } from 'components/Typography'
import { Wrapper } from 'components/layout/FlexComponents'
import React from 'react'
import styled from 'styled-components'
import { preguntas } from 'utils/PreguntasFrecuentesMocks'

const PreguntasWrapper = styled(Wrapper)`
  width: 80%;
  margin: 32px auto;
`

const Title = styled(H1)`
  color: ${({ theme }) => theme.colors.neutral.black};
  justify-self: flex-start;
  align-self: flex-start;
  margin-bottom: 16px;
  font-size: 30px;
`

const PreguntaContent = styled.div`
  padding: 16px 44px;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
  color: ${({ theme }) => theme.colors.neutral.black};
`

const formatInfo = (preguntas) => {
  return preguntas.map((e, index) => ({
    title: e.title,
    component: (
      <PreguntaContent key={index}>
        {e.text.split('\n').map((line, i) => (
          <span key={i}>
            {line}
            <br />
          </span>
        ))}
      </PreguntaContent>
    ),
  }))
}

const PreguntasFrecuentes = () => {
  return (
    <PreguntasWrapper>
      <Title>Preguntas Frecuentes</Title>
      <ExpandableTables
        items={formatInfo(preguntas)}
        className={{ removeBlueBorder: true }}
        style={{
          width: '100%',
          borderLeft: '',
        }}
      ></ExpandableTables>
    </PreguntasWrapper>
  )
}
export default PreguntasFrecuentes
