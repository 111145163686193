import axios from 'axios'
import { queryString } from 'utils/textProcess'

const BusquedaService = {
  buscar: async ({ idCronograma, idCarrito, searchValue, token }) => {
    let data = {
      tipo: 'articulo',
      id_cronograma: idCronograma,
      id_carrito: idCarrito,
      descripcion: searchValue,
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/buscar?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data.productos
      })
      .catch((error) => {
        console.error(error)
      })
  },
}

export default BusquedaService
