import React, { useState } from 'react'
import styled from 'styled-components'
import FooterCopyright from './FooterCopyright'
import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import LogoFacc from 'components/images/LogoFacc'
import { H4, P2 } from 'components/Typography'
import Link from 'components/Button/LinkUnderline'

const helpNav = {
  title: 'Ayuda',
  items: [
    {
      id: 1,
      name: 'Preguntas frecuentes',
      url: '/preguntas-frecuentes',
    },
  ],
}

const infoNav = {
  title: 'Información',
  items: [
    {
      id: 1,
      name: 'Términos y condiciones',
      url: '/terminos-condiciones',
    },
  ],
}

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const TopWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  gap: 12px;

  @media (max-width: 1200px) {
    padding: 24px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    padding: 12px;
  }
`

const Logo = styled(LogoFacc)`
  width: auto;
  height: 54px;

  @media (max-width: 440px) {
    height: 28px;
  }
`

const LinkListContainer = styled.div`
  display: flex;
  align-self: start;
  flex-direction: column;
`

const StyledFooterOptions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 8px 0 0 0;
  list-style-type: none;
  padding: initial;

  @media (max-width: 440px) {
    margin: 0;
  }
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  align-self: center;
  font-weight: 600;
  white-space: nowrap;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.neutral.black};
  &:visited {
    color: ${({ theme }) => theme.colors.neutral.black};
  }
`

const StyledPhoneLink = styled(StyledLink)`
  color: ${({ theme }) => theme.colors.neutral.black};
`

const ContactCard = styled.div`
  border-radius: 25px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.primary.transparentBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.neutral.black};

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 12px 58px;
  }

  @media (max-width: 440px) {
    ${({ isExpanded }) => (isExpanded ? 'display: flex;' : 'display: none;')}
  }
`

const ToggleButton = styled.button`
  display: none;

  @media (max-width: 440px) {
    display: block;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary.transparentBlue};
    border: none;
    padding: 8px 16px;
    margin-top: 6px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.neutral.black};
    font-weight: bold;
    border-radius: 10px;
  }
`

const DataContainer = styled.div`
  display: none;

  @media (max-width: 440px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const TextSpan = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const SpacerLine = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.darkGray};
  width: 1px;
  height: 100%;
  margin: 0 14px;

  @media (max-width: 1366px) {
    margin: 0 6px;
  }

  @media (max-width: 914px) {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (max-width: 914px) {
    gap: 4px;
  }
`

const CenteredRow = styled(Row)`
  flex-grow: 1;
  align-items: center;
  gap: 30px;
  @media (max-width: 520px) {
    gap: 6px;
  }
`

const EmailsRow = styled(Row)`
  @media (max-width: 914px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SmallSpacer = styled.span`
  margin-left: 6px;
  @media (max-width: 520px) {
    display: none;
  }
`

const ConditionalLineBreak = styled.br`
  display: none;
  @media (max-width: 520px) {
    display: initial;
  }
`

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <StyledFooter>
      <TopWraper>
        <CenteredRow>
          <StyledLink href="https://www.faccargentina.coop/" target="_blank">
            <Logo />
          </StyledLink>
          <LinkListContainer>
            <H4>{helpNav.title}</H4>
            <StyledFooterOptions>
              {helpNav.items.map((navItem) => {
                return (
                  <li key={navItem.id}>
                    <StyledLink href={navItem.url}>{navItem.name}</StyledLink>
                  </li>
                )
              })}
            </StyledFooterOptions>
          </LinkListContainer>
          <LinkListContainer>
            <H4>{infoNav.title}</H4>
            <StyledFooterOptions>
              {infoNav.items.map((navItem) => {
                return (
                  <li key={navItem.id}>
                    <StyledLink href={navItem.url}>{navItem.name}</StyledLink>
                  </li>
                )
              })}
            </StyledFooterOptions>
          </LinkListContainer>
        </CenteredRow>
        <ToggleButton onClick={toggleExpand}>
          <DataContainer>
            <P2>Contactos de consulta</P2>
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </DataContainer>
        </ToggleButton>
        <ContactCard isExpanded={isExpanded}>
          <Row>
            <P2>Contactos de consulta</P2>
            <StyledPhoneLink href="tel:+0291-4039019">
              (0291) 4039019
            </StyledPhoneLink>
          </Row>
          <EmailsRow>
            <Col>
              <TextSpan>
                Generales:
                <ConditionalLineBreak />
                <SmallSpacer />
                <StyledLink href="mailto:centraldecompras@faccargentina.coop">
                  centraldecompras@faccargentina.coop
                </StyledLink>
              </TextSpan>
              <TextSpan>
                Proveedores:
                <ConditionalLineBreak />
                <SmallSpacer />
                <StyledLink href="mailto:compras@faccargentina.coop">
                  compras@faccargentina.coop
                </StyledLink>
              </TextSpan>
            </Col>
            <Col>
              <SpacerLine />
            </Col>
            <Col>
              <TextSpan>
                Operaciones:
                <ConditionalLineBreak />
                <SmallSpacer />
                <StyledLink href="mailto:operaciones@faccargentina.coop">
                  operaciones@faccargentina.coop
                </StyledLink>
              </TextSpan>
              <TextSpan>
                Administrativas:
                <ConditionalLineBreak />
                <SmallSpacer />
                <StyledLink href="mailto:administracion@faccargentina.coop">
                  administracion@faccargentina.coop
                </StyledLink>
              </TextSpan>
            </Col>
          </EmailsRow>
        </ContactCard>
      </TopWraper>
      <FooterCopyright />
    </StyledFooter>
  )
}

export default Footer
