import React, { useEffect } from 'react'
import styled from 'styled-components'

import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'
import TableDetalleProveedor from './components/TableDetalleProveedor'
import { useProveedor } from 'contexts/ProveedorContext'
import { useLocation } from 'react-router-dom'
import Loading from 'components/Loading/Loading'
import { useArticulos } from 'contexts/ArticulosContext'
import { useCarrito } from 'contexts/CarritoContext'
import { shuffle } from 'utils/arrayFunctions'
import RotatingLoading from 'components/Loading/Loading'
import { useCronograma } from 'contexts/CronogramaContext'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const TableWrapper = styled.div`
  width: 100%;
`

const PaddedWrapper = styled.div`
  padding: 40px 10%;

  @media (max-width: 1366px) {
    padding: 40px 4%;
  }

  @media (max-width: 440px) {
    padding: 20px 5%;
  }
`

const DetalleProveedorPage = () => {
  const location = useLocation()
  const { currentProveedor, proveedorLoading, getProveedor } = useProveedor()
  const idProveedor = location.state?.idProveedor
  const { promocion, getPromocion, promocionLoading } = useArticulos()
  const { carritoAgrupado } = useCarrito()
  const { cronograma } = useCronograma()

  const formatData = (data) => {
    const arr = []
    data.map((grupo) => {
      if (grupo.articulos.length > 0) {
        arr.push(
          ...grupo.articulos.map((promo) => ({
            title: promo.descripcion,
            proveedor: currentProveedor.proveedor,
            topTag: 'Promo',
            botTags: [],
            location: '',
            path_thumb: promo.path_thumb,
            id: promo.id_articulo,
          })),
        )
      }
    })
    return arr
  }

  useEffect(() => {
    const fetchData = async () => {
      if (carritoAgrupado?.id && idProveedor) {
        await getPromocion(idProveedor)
      }

      if (currentProveedor.id !== idProveedor && !proveedorLoading) {
        await getProveedor(idProveedor)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProveedor.id, carritoAgrupado, idProveedor])

  return (
    <Wrapper>
      <TableWrapper>
        <PaddedWrapper>
          {proveedorLoading ? (
            <Loading />
          ) : (
            <TableDetalleProveedor proveedor={currentProveedor} />
          )}
        </PaddedWrapper>
      </TableWrapper>
      {!promocionLoading ? (
        promocion?.articulos && (
          <>
            {promocion?.articulos.filter((item) => item.articulos.length > 0)
              .length > 0 && (
              <HorizontalPromoCarousel
                title="Promociones"
                type="primary"
                cards={formatData(shuffle(promocion?.articulos), 'promociones')}
                cronogramaHabilitado={cronograma.habilitado}
              />
            )}
          </>
        )
      ) : (
        <RotatingLoading />
      )}
    </Wrapper>
  )
}

export default DetalleProveedorPage
