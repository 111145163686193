import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import { useModal } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useCronograma } from 'contexts/CronogramaContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { P2 } from 'components/Typography'
import { useProveedor } from 'contexts/ProveedorContext'
import DropdownNotification from 'components/Notification/DropdownNotification'

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 16px;
`

const FullWidthInputContainer = styled.div`
  grid-column: span 2;
`

const GridInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const StyledInput = styled(Input)`
  border-radius: 8px;
`

const StyledDatepicker = styled.input`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
`

const CustomSelect = styled(Select)`
  width: 100%;
`

const CustomLoading = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ConsolidarAsociadaModal = ({
  proveedorId,
  confirmFeedback,
  afterClose,
}) => {
  const [errores, setErrores] = useState([])
  const { closeModal } = useModal()
  const { finalizar } = useCarrito()
  const orderInputRef = useRef()
  const { pedidosAConsolidar } = usePedidos()
  const { currentProveedor, getProveedor, proveedorLoading } = useProveedor()
  const [zonasProveedor, setZonasProveedor] = useState([])
  const [lugaresProveedor, setLugaresProveedor] = useState([])
  const {
    bestZona,
    mejorProveedorZona,
    getZonasProveedor,
    getLugaresProveedores,
    zonaLoading,
  } = useCronograma()

  const [inputs, setInputs] = useState({
    oc_grupo: pedidosAConsolidar.ordenDeCompra || '',
    //TODO: id_zona 1
    id_zona: 1,
    observacion: '',
    id_lugar:
      Object.keys(lugaresProveedor).length !== 0
        ? Object.keys(lugaresProveedor)[0]
        : 0,
    fecha_vto: getDefaultDate(),
  })

  useEffect(() => {
    const fetchZonasProveedor = async () => {
      const proveedor = await getProveedor(proveedorId)
      const zonas = await getZonasProveedor(proveedor.id)

      const currentZonas = Object.keys(zonas)[0]
      const lugares = await getLugaresProveedores(proveedor.id, currentZonas)
      setZonasProveedor(zonas)
      setLugaresProveedor(lugares)

      if (proveedor.tipo_proveedor === '1') {
        setInputs((prevInputs) => ({
          ...prevInputs,
          id_lugar: 387,
          id_zona: 1,
        }))
      } else {
        setInputs((prevInputs) => ({
          ...prevInputs,
          id_lugar:
            Object.keys(lugares).length !== 0 ? Object.keys(lugares)[0] : 0,
          id_zona: currentZonas,
        }))
      }
    }

    if (
      // proveedorId !== currentProveedor.id &&
      !zonaLoading &&
      !proveedorLoading
    )
      fetchZonasProveedor()

    if (orderInputRef.current) {
      orderInputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proveedorId])

  function getDefaultDate() {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + 1)
    return currentDate.toISOString().split('T')[0]
  }

  async function checkDataAndConsolidatePedido() {
    const erroresNuevos = []
    if (inputs.oc_grupo === '') {
      erroresNuevos.push('oc_grupo')
    }
    if (inputs.id_zona === '') {
      erroresNuevos.push('id_zona')
    }
    if (inputs.id_lugar === '') {
      erroresNuevos.push('id_lugar')
    }
    if (!inputs.fecha_vto) {
      erroresNuevos.push('fecha_vto')
    }

    if (erroresNuevos.length > 0) {
      setErrores(erroresNuevos)
    } else {
      setErrores([])
      await finalizar({
        idProveedor: currentProveedor.id,
        ocGrupo: inputs.oc_grupo,
        fechaVto: inputs.fecha_vto,
        observacion: inputs.observacion,
        idZona: inputs.id_zona,
        idLugarEntrega: inputs.id_lugar,
        tieneReqLog: true,
        superoReqLog: true,
      })
      confirmFeedback()
      closeModal()
      afterClose()
    }
  }

  return (
    <Modal
      content={{
        type: 'info',
        icon: 'bell',
        title: 'Estás por consolidar tu pedido',
        text: [],
        primaryButton: {
          function: checkDataAndConsolidatePedido,
          text: 'Continuar',
          disabled:
            currentProveedor.tipo_proveedor !== '1' &&
            Object.keys(lugaresProveedor).length === 0,
        },
        secondaryButton: {
          function: () => {
            closeModal()
            afterClose()
          },
          text: 'Volver y revisar',
        },
        afterClose,
      }}
    >
      <InputContainer>
        <GridInput>
          <div>
            <StyledLabel htmlFor="Orden de compra">Orden de compra</StyledLabel>
            <StyledInput
              border={true}
              ref={orderInputRef}
              type="number"
              error={errores.includes('oc_grupo')}
              placeholder="xxx-xxx-xxx"
              maxLength="9"
              value={inputs.oc_grupo}
              disabled={
                pedidosAConsolidar.ordenDeCompra !== undefined &&
                pedidosAConsolidar.ordenDeCompra !== null
              }
              onChange={(event) =>
                setInputs((prevInputs) => ({
                  ...prevInputs,
                  oc_grupo: event.target.value,
                }))
              }
            />
          </div>
          <div>
            <StyledLabel htmlFor="Fecha de vencimiento">
              Fecha de vencimiento
            </StyledLabel>
            <StyledDatepicker
              type="date"
              value={inputs.fecha_vto}
              onChange={(event) =>
                setInputs((prevInputs) => ({
                  ...prevInputs,
                  fecha_vto: event.target.value,
                }))
              }
            />
          </div>
        </GridInput>

        <GridInput>
          <div>
            <StyledLabel htmlFor="Zona">Zona</StyledLabel>
            {zonasProveedor === null &&
            Object.keys(zonasProveedor).length <= 0 ? (
              <P2>No hay zonas</P2>
            ) : zonaLoading ? (
              <CustomLoading>
                <RotatingLoader />
              </CustomLoading>
            ) : (
              <CustomSelect
                id="Zona"
                disabled={currentProveedor.tipo_proveedor === '1'}
                options={zonasProveedor}
                bestZona={bestZona ? bestZona[currentProveedor.id] : false}
                selectedOption={inputs.id_zona}
                toggleOptionsAction={() =>
                  mejorProveedorZona(currentProveedor.id)
                }
                onChange={async (zonaId) => {
                  const lugares = await getLugaresProveedores(
                    currentProveedor.id,
                    zonaId,
                  )
                  setLugaresProveedor(lugares)
                  return setInputs((prevInputs) => ({
                    ...prevInputs,
                    id_zona: zonaId,
                    id_lugar:
                      Object.keys(lugares).length !== 0
                        ? Object.keys(lugares)[0]
                        : 0,
                  }))
                }}
              />
            )}
          </div>
          <div>
            <StyledLabel htmlFor="Lugares de Entrega">
              Lugares de Entrega
            </StyledLabel>
            <CustomSelect
              id="Lugar"
              options={lugaresProveedor}
              selectedOption={inputs.id_lugar}
              onChange={(selectedOption) => {
                return setInputs((prevInputs) => ({
                  ...prevInputs,
                  id_lugar: selectedOption,
                }))
              }}
              disabled={
                Object.keys(lugaresProveedor).length === 0 ||
                currentProveedor.tipo_proveedor === '1'
              }
            />
          </div>
        </GridInput>

        <FullWidthInputContainer>
          <StyledLabel htmlFor="Observaciones">Observaciones</StyledLabel>
          <StyledInput
            type="text"
            border={true}
            placeholder="Observación"
            value={inputs.observacion}
            onChange={(event) =>
              setInputs((prevInputs) => ({
                ...prevInputs,
                observacion: event.target.value,
              }))
            }
          />
        </FullWidthInputContainer>

        {currentProveedor.tipo_proveedor === '1' ? (
          <DropdownNotification
            text="No está disponible el cambio de zonas en las Marcas Líderes."
            type="alerta"
          />
        ) : (
          (errores.length > 0 ||
            (zonasProveedor.length !== 0 &&
              Object.keys(lugaresProveedor).length === 0)) && (
            <DropdownNotification
              text={
                errores.length > 0
                  ? 'Por favor completá los datos faltantes'
                  : 'No existen lugares de entrega asociados a esa zona. Eligí otra zona para continuar.'
              }
              type="error"
            />
          )
        )}
      </InputContainer>
    </Modal>
  )
}

export default ConsolidarAsociadaModal
