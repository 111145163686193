import React from 'react'
import styled from 'styled-components'
import {
  AlertTriangle,
  Bell,
  CheckCircle,
  Slash,
  X,
} from 'feather-icons-react/build/IconComponents'

import { Button } from 'components'
import { P2 } from 'components/Typography'

const DropdownNotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 4px solid ${({ type }) => formatNotification()[type].textColor};
  border-radius: 5px;
  padding: 12px 0;
  margin: 15px 0;
  background-color: ${({ type }) => formatNotification()[type].backgroundColor};
  color: ${({ type }) => formatNotification()[type].textColor};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
`

const CloseIcon = styled(X)`
  cursor: pointer;
  margin: 0 8px;
`

const InteractiveArea = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 0 20px;
`

const StyledButton = styled(Button)`
  background-color: ${({ typeNotification }) =>
    formatNotification()[typeNotification].textColor};
  border: none;
`

const TextNotification = styled(P2)``

const InfoNotification = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 18px;
  margin: 0 20px;
`

const formatNotification = () => ({
  error: {
    textColor: '#DC2626',
    backgroundColor: '#fef0f0',
    icon: <Slash size={20} />,
  },
  validacion: {
    textColor: '#10B981',
    backgroundColor: '#e8f8f3',
    icon: <CheckCircle size={20} />,
  },
  alerta: {
    textColor: '#F59E0B',
    backgroundColor: '#fef6e7',
    icon: <AlertTriangle size={20} />,
  },
  notificacion: {
    textColor: '#0062FF',
    backgroundColor: '#eaf0ff',
    icon: <Bell size={20} />,
  },
})

// function hexToRgba(hex, percent) {
//   // Eliminar el '#' al inicio si está presente
//   hex = hex.replace('#', '')

//   // Parsear los valores hexadecimales a RGB
//   let r = parseInt(hex.substring(0, 2), 16)
//   let g = parseInt(hex.substring(2, 4), 16)
//   let b = parseInt(hex.substring(4, 6), 16)

//   // Calcular los nuevos valores de RGB aclarados
//   r = Math.min(255, Math.floor(r + (255 - r) * percent))
//   g = Math.min(255, Math.floor(g + (255 - g) * percent))
//   b = Math.min(255, Math.floor(b + (255 - b) * percent))

//   // Convertir los valores RGB de vuelta a hexadecimal
//   r = r.toString(16).padStart(2, '0')
//   g = g.toString(16).padStart(2, '0')
//   b = b.toString(16).padStart(2, '0')

//   // Devolver el color en formato hexadecimal
//   return `#${r}${g}${b}`
// }

const DropdownNotification = ({
  text,
  icon,
  action,
  actionText,
  closeAction,
  type = 'notificacion',
  className,
}) => {
  return (
    <DropdownNotificationWrapper className={className} type={type}>
      <InfoNotification>
        {icon ? icon : formatNotification()[type].icon}
        <TextNotification>{text}</TextNotification>
      </InfoNotification>
      {actionText && action && (
        <InteractiveArea>
          <StyledButton
            size="medium"
            type="primary"
            text={actionText}
            onClick={action}
            typeNotification={type}
          />
          {closeAction && <CloseIcon size={24} onClick={closeAction} />}
        </InteractiveArea>
      )}
    </DropdownNotificationWrapper>
  )
}

export default DropdownNotification
