import axios from 'axios'

const AuthService = {
  login: async (username, password) => {
    let data = JSON.stringify({
      username,
      password,
      remember_me: false,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/login`,
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        return {
          token: response.data.data,
        }
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  logout: async (token) => {
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/logout`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  },

  getUserData: async (token) => {
    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/user`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return await axios
      .request(config)
      .then((response) => response.data.data)
      .catch((error) => {
        console.error(error)
      })
  },

  updatePassword: async (password, passwordConfirmation, token) => {
    let data = JSON.stringify({
      password,
      password_confirmation: passwordConfirmation,
    })
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/update-password`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    }

    return axios
      .request(config)
      .then((response) => response.data.data)
      .catch((error) => {
        console.error(error)
      })
  },

  resetPassword: async (password, passwordConfirmation, token) => {
    let data = JSON.stringify({
      password,
      password_confirmation: passwordConfirmation,
      token,
    })
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/reset-password`,
      data,
    }

    return axios
      .request(config)
      .then((response) => response.data.data)
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  forgotPassword: async (username) => {
    let data = JSON.stringify({
      username,
    })
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/forgot-password`,
      data,
    }

    return axios
      .request(config)
      .then((response) => response)
      .catch((error) => {
        console.error(error)
        throw error
      })
  },
}

export default AuthService
