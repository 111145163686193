import React, { useEffect, useState } from 'react'

import NotificationCenter from 'components/Notification/NotificationCenter'
import { Wrapper } from 'components/layout/FlexComponents'
import styled from 'styled-components'
import ProveedoresTable from './components/Tables/ProveedoresTable'
import DisplayAsociadasIndividuales from './AsociadasIndividuales/DisplayAsociadasIndividuales'
import PedidosTable from './components/Tables/PedidosTable'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import { ModalProvider } from 'contexts/ModalContext'
import DropdownNotification from 'components/Notification/DropdownNotification'

const WrapperCoordinacionPage = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  box-sizing: border-box;
`

const PaddedWrapper = styled.div`
  padding: 0 10%;
  width: 80%;
`

const WrapperAsocidasInd = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.lighterBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 82px 10%;
  box-sizing: border-box;
`

const orderName = (proovedoresData) => {
  return proovedoresData?.sort((a, b) => {
    if (a.nombre_comercial < b.nombre_comercial) {
      return -1
    }
    if (a.nombre_comercial > b.nombre_comercial) {
      return 1
    }
    return 0
  })
}

const CoordinacionPage = () => {
  const { user, token } = useAuth()
  const { getPedidosAConsolidar, pedidosAConsolidar, pedidosLoading } =
    usePedidos()
  const { cronograma } = useCronograma()

  const proveedoresCronograma =
    user?.rol?.id_rol === '1'
      ? orderName(
          cronograma?.proveedores?.map((prov) => {
            return {
              id: prov.id,
              nombre_comercial: prov.nombre_comercial,
              habilitado: prov.habilitado,
              path: prov.path,
              zona: prov.zonaDefault,
              zonas: prov.zonas?.reduce((acc, item) => {
                acc[item.id] = item.descripcion
                return acc
              }, {}),
            }
          }),
        )
      : []

  const [openTable, setOpenTable] = useState({
    proveedores: !cronograma.habilitado,
    pedidos: cronograma.habilitado,
  })

  useEffect(() => {
    const fetchPedidosAConsolidar = async () => {
      try {
        await getPedidosAConsolidar()
      } catch (error) {
        console.error('Error al obtener los pedidos a consolidar:', error)
      }
    }

    fetchPedidosAConsolidar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])

  const toggleOpen = (nameTable) => {
    setOpenTable((prevState) => {
      return {
        ...prevState,
        [nameTable]: !prevState[nameTable],
      }
    })
  }

  return (
    <WrapperCoordinacionPage>
      <PaddedWrapper>
        <NotificationCenter />
        <ModalProvider>
          <SearchAndFilterTableProvider
            initialValueData={proveedoresCronograma}
            searchParameter={'nombre_comercial'}
          >
            <ProveedoresTable
              openTable={openTable}
              setOpenTable={setOpenTable}
              toggleOpen={toggleOpen}
            />
          </SearchAndFilterTableProvider>
        </ModalProvider>
        {openTable.proveedores && (
          <DropdownNotification
            text="Las zonas de reparto más recomendadas para tu ubicación
               aparecerán con una estrella amarilla en el menú
               desplegable"
            type="notificacion"
          />
        )}
        <ModalProvider>
          <SearchAndFilterTableProvider
            initialValueData={pedidosAConsolidar.proveedores}
            searchParameter={'nombre_comercial'}
          >
            <PedidosTable
              openTable={openTable}
              setOpenTable={setOpenTable}
              toggleOpen={toggleOpen}
              pedidosLoading={pedidosLoading}
            />
          </SearchAndFilterTableProvider>
        </ModalProvider>
      </PaddedWrapper>
      <WrapperAsocidasInd>
        <DisplayAsociadasIndividuales />
      </WrapperAsocidasInd>
    </WrapperCoordinacionPage>
  )
}

export default CoordinacionPage
