import React, { createContext, useContext, useState } from 'react'

import AuthCronograma from 'services/cronograma'
import { useAuth } from './AuthContext'
import { getLocalStorageItem } from 'utils/loadData'
const CronogramaContext = createContext()

export const useCronograma = () => useContext(CronogramaContext)

export const CronogramaProvider = ({ children }) => {
  const { user, token } = useAuth()
  const [cronogramaLoading, setCronogramaLoading] = useState(false)
  const [cronograma, setCronograma] = useState(() =>
    getLocalStorageItem('cronograma', {}),
  )
  const [bestZona, setBestZona] = useState()
  const [zonaLoading, setZonaLoading] = useState(false)

  const getCronograma = async () => {
    try {
      const data = await AuthCronograma.getCronogramaData(token)
      localStorage.setItem('cronograma', JSON.stringify(data))
      setCronograma(data)
      return data
    } catch (error) {
      console.error('Error al obtener los datos del cronograma:', error)
    }
  }

  const habilitarDeshabilitarProveedor = async (habilitado, idProveedor) => {
    setCronogramaLoading(true)
    await AuthCronograma.habilitarDeshabilitarProveedor({
      idProveedor,
      habilitado,
      idGrupo: user.grupo.id_grupo,
      idCronograma: cronograma.id_cronograma,
      token,
    })
      .then(async (resp) => {
        console.log(resp)
        await getCronograma()
      })
      .catch((error) => {
        console.error('Error al obtener los datos del cronograma:', error)
      })
      .finally(() => setCronogramaLoading(false))
  }

  const habilitarCronograma = () => {
    setCronogramaLoading(true)
    AuthCronograma.habilitarCronograma({
      idGrupo: user.grupo.id_grupo,
      idCronograma: cronograma.id_cronograma,
      token,
    })
      .then((resp) => {
        console.log(resp)
        const cronogramaLocal = getLocalStorageItem('cronograma', {})

        cronogramaLocal.habilitado = !cronogramaLocal.habilitado
        setCronograma(cronogramaLocal)
        localStorage.setItem('cronograma', JSON.stringify(cronogramaLocal))
      })
      .catch((error) => {
        console.error('Error al obtener los datos del cronograma:', error)
      })
      .finally(() => setCronogramaLoading(false))
  }

  const mejorProveedorZona = async (idProveedor) => {
    if (!(bestZona && bestZona[idProveedor])) {
      setCronogramaLoading(true)
      try {
        const resp = await AuthCronograma.mejorProveedorZona({
          idProveedor: idProveedor,
          token,
        })

        const updatedBestZona = {
          ...bestZona,
          [idProveedor]: resp.data.zona,
        }
        setBestZona(updatedBestZona)
      } catch (error) {
        console.error('Error al obtener mejor proveedor de la zona:', error)
      } finally {
        setCronogramaLoading(false)
      }
    }
  }

  const cambiarZona = async (idZona, idProveedor, idCarrito) => {
    setZonaLoading(true)
    const params = {
      idProveedor: idProveedor,
      idZona: idZona,
      token,
    }
    if (user.rol.id_rol !== '3') {
      params.idGrupo = user.grupo.id_grupo
    } else {
      params.idCarrito = idCarrito
    }
    await AuthCronograma.cambiarZonaProveedor(params)
      .then((resp) => {
        console.log(resp)
        const localStorageData = getLocalStorageItem('cronograma', {})

        const proveedoresActualizados = [...localStorageData.proveedores]
        const indexProveedor = proveedoresActualizados.findIndex(
          (proveedor) => proveedor.id === idProveedor,
        )

        if (indexProveedor !== -1) {
          proveedoresActualizados[indexProveedor] = {
            ...proveedoresActualizados[indexProveedor],
            zonaDefault: idZona,
          }

          const cronogramaLocal = {
            ...localStorageData,
            proveedores: proveedoresActualizados,
          }

          localStorage.setItem('cronograma', JSON.stringify(cronogramaLocal))
          setCronograma(cronogramaLocal)
        } else {
          console.error('Proveedor no encontrado')
        }
      })
      .catch((error) => {
        console.error('Error al obtener los datos del cronograma:', error)
      })
      .finally(() => setZonaLoading(false))
  }

  const getZonasProveedor = async (idProveedor) => {
    setZonaLoading(true)
    try {
      const resp = await AuthCronograma.zonasProveedor({
        idProveedor,
        token,
      })
      return resp.data.zonas[idProveedor] !== null
        ? resp.data.zonas[idProveedor].reduce((acc, item) => {
            acc[item.id] = item.descripcion
            return acc
          }, {})
        : null
    } catch (error) {
      console.error('Error al obtener mejor proveedor de la zona:', error)
    } finally {
      setZonaLoading(false)
    }
  }

  const getLugaresProveedores = async (idProveedor, idZona) => {
    setZonaLoading(true)
    try {
      const resp = await AuthCronograma.lugaresProveedores({
        idProveedor,
        idZona,
        token,
      })
      return resp.data.lugares.reduce((acc, item) => {
        acc[item.id] = item.descripcion
        return acc
      }, {})
    } catch (error) {
      console.error('Error al obtener mejor proveedor de la zona:', error)
    } finally {
      setZonaLoading(false)
    }
  }

  const contextValues = {
    cronograma,
    setCronograma,
    habilitarDeshabilitarProveedor,
    habilitarCronograma,
    cambiarZona,
    mejorProveedorZona,
    cronogramaLoading,
    bestZona,
    getCronograma,
    zonaLoading,
    getZonasProveedor,
    getLugaresProveedores,
  }

  return (
    <CronogramaContext.Provider value={contextValues}>
      {children}
    </CronogramaContext.Provider>
  )
}
