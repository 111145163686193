import React, { useState } from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import HeaderTable from 'components/Table/HeaderTable'
import { BtnM, P2 } from 'components/Typography'
import CurrencyFormat from 'react-currency-format'
import { Button } from 'components/index'
import { Edit2 } from 'feather-icons-react/build/IconComponents'
import { useModal } from 'contexts/ModalContext'
import EditPedidoModal from 'components/Modal/EditPedidoModal'

const TableWrapper = styled.div`
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const CustomHeader = styled(HeaderTable)`
  padding-right: 15px;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ScrolleableTable = styled.div`
  max-height: 450px;
  overflow: scroll;
`

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`

const TotalesTableSection = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 20px 46px 20px 20px;
  & > :nth-child(1) {
    grid-column-start: 2;
  }
`

const formatData = ({ articulos }) =>
  articulos.map((e) => ({
    EAN: <P2>{e.ean13}</P2>,
    Producto: <P2>{e.descripcion}</P2>,
    'Bultos pedidos': <P2>{e.cantidad_carrito}</P2>,
    'Subtotal s/iva': (
      <CurrencyFormat
        value={parseFloat(e.precio)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    'subtotal c/iva': (
      <CurrencyFormat
        value={parseFloat(e.precio * (1 + parseFloat(e.iva) / 100))}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
  }))

const headers = [
  { name: 'EAN', isOrderable: false },
  { name: 'Producto', isOrderable: false },
  { name: 'Subtotal Bultos', isOrderable: false },
  { name: 'Neto', isOrderable: false },
  { name: 'subtotal c/iva', isOrderable: false },
]

const PedidosConfirmadosTable = ({
  pedidos,
  totales,
  idCronograma,
  idCronogramaPedido,
  idProveedor,
}) => {
  const { openModal, isModalOpen } = useModal()
  const [isEditPedidoModalOpen, setIsEditPedidoModalOpen] = useState(false)

  const handleEditPedido = () => {
    openModal()
    setIsEditPedidoModalOpen(true)
  }

  const afterClose = () => {
    setIsEditPedidoModalOpen(false)
  }

  return (
    <TableWrapper>
      <CustomHeader
        numerate={false}
        headers={headers}
        gridTemplate="1fr, 2fr, 1fr, 1fr, 1fr"
      />
      {pedidos?.length > 0 ? (
        <ScrolleableTable>
          <Table
            data={formatData({
              articulos: pedidos,
            })}
            numerate={false}
            gridTemplate="1fr, 2fr, 1fr, 1fr, 1fr"
          />
        </ScrolleableTable>
      ) : (
        <EmptyTableData>No se encontraron artículos</EmptyTableData>
      )}
      <TotalesTableSection>
        <BtnM>Totales:</BtnM>
        <BtnM>{totales?.bultos}</BtnM>
        <BtnM>
          <CurrencyFormat
            value={parseFloat(totales?.subtotal)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnM>
        <BtnM>
          <CurrencyFormat
            value={parseFloat(totales?.total)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnM>
      </TotalesTableSection>
      <WrapperButton>
        {idCronograma === idCronogramaPedido ? (
          <Button
            text={'Editar pedido'}
            type={'primary'}
            icon={<Edit2 size={14} />}
            onClick={async () => {
              handleEditPedido()
            }}
          />
        ) : (
          <></>
        )}
      </WrapperButton>
      {isModalOpen && isEditPedidoModalOpen && (
        <EditPedidoModal
          idProveedor={idProveedor}
          idCronograma={idCronograma}
          afterClose={afterClose}
        />
      )}
    </TableWrapper>
  )
}

export default PedidosConfirmadosTable
